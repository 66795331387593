import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import i18nService from "@/core/services/i18n.service.js";
import { amplitudeApiKeys, initAmplitude } from "@/core/services/amplitude.service";

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = window.__env.developer ? 'http://localhost/api' : `${window.__env.api}/api`;
    initAmplitude(window.location.host === 'ru-spp.deskki.pro' ? amplitudeApiKeys.McDonProd : amplitudeApiKeys.McDonDev);
  },

  enterPoint() {
    return window.__env.developer ? 'http://localhost/login' : `${window.__env.api}/login`;
  },

  refreshToken() {
    return `${window.__env.api}/api/auth/token-refresh`;
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
  },

  setLanguageHeader() {
    Vue.axios.defaults.headers.common[
        "Accept-Language"
        ] = i18nService.getActiveLanguage();
  },

  query(resource, params) {
    return Vue.axios.get(resource, params);
    // .catch(error => {
    //   // console.log(error);
    //   throw new Error(`[KT] ApiService ${error}`);
    // });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    if (slug) return Vue.axios.get(`${resource}/${slug}`);
    return Vue.axios.get(`${resource}`);
    // .catch(error => {
    //   // console.log(error);
    //   throw new Error(`[KT] ApiService ${error}`);
    // });
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    return Vue.axios.post(`${resource}`, params);
  },

  postWithQuery(resource, params, query) {
    return Vue.axios.post(`${resource}`, params, query);
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, data) {
    if (data) return Vue.axios.delete(`${resource}`, { data: data });
    return Vue.axios.delete(resource);
    // .catch(error => {
    //   // console.log(error);
    //   throw new Error(`[RWV] ApiService ${error}`);
    // });
  }
};

export default ApiService;
