import ApiService from "@/core/services/api.service";
import { SET_ERROR, SET_SUCCESS } from "@/core/services/store/auth.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";
import i18n from "../../../plugins/vue-i18n";

// action types
export const GET_PRODUCTION_CALENDAR = "getProductionCalendar";
export const PUT_PRODUCTION_CALENDAR = "putProductionCalendar";
export const PUT_MAKE_HOLIDAY = "putMakeHoliday";
export const PUT_MAKE_JOB_DAY = "putMakeJobDay";
export const GET_HOLIDAYS_LIST = "getHolidaysList";

// mutation types
export const SET_PRODUCTION_CALENDAR = "setProductionCalendar";
export const SET_HOLIDAYS_LIST = "setHolidaysList";
export const PUT_HOLIDAYS_LIST = "putHolidaysList";

const state = {
    productCalendar: [],
    holidaysList: null
};

const getters = {
    productCalendar(state) {
        const entries = Object.entries(state.productCalendar);
        let days = [];
        entries.forEach(item => {
            item = Object.entries(item[1]);
            item.day = item[0][0];
            switch (item.day) {
                case 'monday':
                    item.id = 0
                    break;
                case 'tuesday':
                    item.id = 1
                    break;
                case 'wednesday':
                    item.id = 2
                    break;
                case 'thursday':
                    item.id = 3
                    break;
                case 'friday':
                    item.id = 4
                    break;
                case 'saturday':
                    item.id = 5
                    break;
                case 'sunday':
                    item.id = 6
                    break;
            }
            item.startTime = item[0][1].startTime;
            item.endTime = item[0][1].endTime;
            item.isHoliday = item[0][1].isHoliday;
            delete item[0]; delete item[1];
            days.push(item);
        })
        return days.sort(function(a, b) {
            return a.id - b.id;
          });
    },
    holidaysList(state) {
        return state.holidaysList;
    }
};

const actions = {
    [GET_PRODUCTION_CALENDAR](context) {
        ApiService.get("management/settings/production-calendar/show")
            .then(({ data }) => {
                context.commit(SET_PRODUCTION_CALENDAR, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [PUT_PRODUCTION_CALENDAR](context, payload) {
        return new Promise(resolve => {
            ApiService.put("management/settings/production-calendar/edit", payload)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_PRODUCTION_CALENDAR, data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.SAVE_TEXT") );
                    sendAmplitudeEvent('production-calendar edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('production-calendar edit', { 'result': 'error' });
                });
        });
    },
    [PUT_MAKE_HOLIDAY](context, payload) {
        return new Promise(resolve => {
            ApiService.put("management/settings/production-calendar/make-holiday", payload)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_PRODUCTION_CALENDAR, data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.SAVE_TEXT") );
                    sendAmplitudeEvent('make-holiday edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('make-holiday edit', { 'result': 'error' });
                });
        });
    },
    [PUT_MAKE_JOB_DAY](context, payload) {
        return new Promise(resolve => {
            ApiService.put("management/settings/production-calendar/make-job-day", payload)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_PRODUCTION_CALENDAR, data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.SAVE_TEXT") );
                    sendAmplitudeEvent('make-job-day edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('make-job-day edit', { 'result': 'error' });
                });
        });
    },
    [GET_HOLIDAYS_LIST](context) {
        ApiService.get("management/settings/production-calendar/holidays/list")
            .then(({ data }) => {
                context.commit(SET_HOLIDAYS_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [PUT_HOLIDAYS_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.put("management/settings/production-calendar/holidays/edit", payload)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_HOLIDAYS_LIST, data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.SAVE_TEXT") );
                    sendAmplitudeEvent('holidays edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('holidays edit', { 'result': 'error' });
                });
        });
    }
};

const mutations = {
    [SET_PRODUCTION_CALENDAR](state, data) {
        state.productCalendar = data;
    },
    [SET_HOLIDAYS_LIST](state, data) {
        state.holidaysList = data.dates;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
