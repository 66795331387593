import ApiService from "@/core/services/api.service";
import { SET_ERROR, SET_SUCCESS } from "@/core/services/store/auth.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";
import axios from "axios";
import $ from "jquery";
import i18n from "../../../plugins/vue-i18n";

// action types
export const GET_BOOKING_CALENDAR_LIST = "getBookingCalendarList";
export const POST_NEW_BOOKING_CALENDAR_DESK = "postNewBookingCalendarDesk";
export const PUT_BOOKING_CALENDAR_DESK = "putBookingCalendarDesk";
export const CANCEL_BOOKING_CALENDAR_DESK = "cancelBookingCalendarDesk";
export const EXPORT_BOOKING_CALENDAR_DESK = "exportBookingCalendarDesk";

// mutation types
export const SET_BOOKING_CALENDAR_LIST = "setBookingCalendarList";

const state = {
    bookingList: []
};

const getters = {
    bookingList(state) {
        return state.bookingList;
    }
};

const actions = {
    [GET_BOOKING_CALENDAR_LIST](context, payload) {
        context.commit(SET_BOOKING_CALENDAR_LIST, []);
        return new Promise(resolve => {
            ApiService.postWithQuery("booking-calendar/list", payload, { params: { limit: 500 }} )
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_BOOKING_CALENDAR_LIST, data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [POST_NEW_BOOKING_CALENDAR_DESK](context, payload) {
        return new Promise(resolve => {
            ApiService.post("booking-calendar/booking/desk/create", payload.form)
                .then(({data}) => {
                    resolve(data);
                    if (data.length > 0) {
                        data.forEach(item => {
                            $.notify({
                                title: item.user ? item.user.name + " - " + item.date : item.date,
                                message: item.message
                            },{
                                type: 'danger',
                                delay: 7000,
                                placement: {
                                    from: "bottom",
                                    align: "left"
                                },
                                allow_dismiss: false,
                                z_index: 1031,
                            });
                        });
                        sendAmplitudeEvent('booking-calendar create', { 'result': 'error' });
                    } else {
                        context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.BOOKING_CREATED") );
                        sendAmplitudeEvent('booking-calendar create', { 'result': 'success' });
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('booking-calendar create', { 'result': 'error' });
                })
        });
    },
    [PUT_BOOKING_CALENDAR_DESK](context, payload) {
        return new Promise(resolve => {
            ApiService.put("booking-calendar/booking/desk/edit", payload.form)
                .then(({data}) => {
                    resolve(data);
                    if (data.length > 0) {
                        data.forEach(item => {
                            $.notify({
                                title: item.user ? item.user.name + " - " + item.date : item.date,
                                message: item.message
                            },{
                                type: 'danger',
                                delay: 7000,
                                placement: {
                                    from: "bottom",
                                    align: "left"
                                },
                                allow_dismiss: false,
                                z_index: 1031,
                            });
                        });
                        sendAmplitudeEvent('booking-calendar edit', { 'result': 'error' });
                    } else {
                        context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.BOOKING_EDITED") );
                        sendAmplitudeEvent('booking-calendar edit', { 'result': 'success' });
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('booking-calendar edit', { 'result': 'error' });
                })
        });
    },
    [CANCEL_BOOKING_CALENDAR_DESK](context, payload) {
        return new Promise(resolve => {
            ApiService.put("booking-calendar/booking/desk/cancellation", payload.form)
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.BOOKING_CANCELED") );
                    sendAmplitudeEvent('booking-calendar delete', { 'result': 'success' });
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('booking-calendar delete', { 'result': 'error' });
                })
        });
    },
    [EXPORT_BOOKING_CALENDAR_DESK](context, payload) {
        ApiService.postWithQuery("booking-calendar/booking/desk/export", payload.form, {
                responseType: 'blob',
                headers: { 'Accept': 'application/vnd.ms-excel' }
            })
            .then(({data}) => {
                const url = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `export-file-${payload.form.from}-${payload.form.to}.xlsx`
                );
                document.body.appendChild(link);
                link.click();
                sendAmplitudeEvent('booking-calendar export', { 'result': 'success' });
            })
            .catch(({response}) => {
                context.commit(SET_ERROR, response.data);
                sendAmplitudeEvent('booking-calendar export', { 'result': 'error' });
            });
    }
};

const mutations = {
    [SET_BOOKING_CALENDAR_LIST](state, data) {
        state.bookingList = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
