import ApiService from "@/core/services/api.service";
import { SET_ERROR, SET_SUCCESS } from "@/core/services/store/auth.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";
import i18n from "../../../plugins/vue-i18n";

// action types
export const GET_ACCESS_TOKEN = "getAccessToken";
export const PUT_ACCESS_TOKEN = "putAccessToken";
export const GET_SIGUR_ACCESS_POINTS_LIST = "getSigurAccessPointsList";
export const GET_SIGUR_POINT = "getSigurPoint";
export const POST_NEW_SIGUR_POINT = "postNewSigurPoint";
export const PUT_SIGUR_POINT = "putSigurPoint";
export const DELETE_SIGUR_POINT = "deleteSigurPoint";
export const ADD_ACCESS_POINT_GROUP = "addAccessPointGroup";
export const REMOVE_ACCESS_POINT_GROUP = "removeAccessPointGroup";
export const GET_SIGUR_PERIOD_SETTINGS = "getSigurPeriodSettings";
export const PUT_SIGUR_PERIOD_SETTINGS = "putSigurPeriodSettings";
export const GET_PACS_LOGS = "getPacsLogs";

export const GET_AZURE_SYNC_STATUS = "getAzureSyncStatus";
export const GET_AZURE_AUTH = "getAzureAuth";
export const GET_AZURE_CHECK_SYNC = "getAzureCheckSync";
export const PUT_AZURE_CANCEL_SYNC = "putAzureCancelSync";
export const GET_AZURE_MAPPING_DATA = "getAzureMappingData";
export const POST_AZURE_MAPPING_DATA = "postAzureMappingData";
export const GET_AZURE_LOGS = "getAzureLogs";

// mutation types
export const SET_ACCESS_TOKEN = "setAccessToken";
export const SET_SIGUR_ACCESS_POINTS_LIST = "setSigurAccessPointsList";
export const SET_SIGUR_POINT = "setSigurPoint";
export const SET_SIGUR_PERIOD_SETTINGS = "setSigurPeriodSettings";
export const SET_PACS_LOGS = "setPacsLogs";

export const SET_AZURE_SYNC_STATUS = "setAzureSyncStatus";
export const SET_AZURE_MAPPING_DATA = "setAzureMappingData";
export const SET_AZURE_LOGS = "setAzureLogs";

const state = {
    sigurAccessToken: {
        value: null
    },
    sigurAccessPoints: {
        items: [],
        pagination: {}
    },
    sigurAccessPoint: {},
    sigurPeriodSettings: {
        value: null
    },
    pacsLogs: {
        items: [],
        pagination: {}
    },
    azureSyncStatus: {
        actively: null
    },
    azureMappingData: {
        calendar_users: [],
        meeting_rooms: [],
        related: []
    },
    azureLogs: {
        items: [],
        pagination: {}
    }
};

const getters = {
    sigurAccessToken(state) {
        return state.sigurAccessToken;
    },
    sigurAccessPoints(state) {
        return state.sigurAccessPoints;
    },
    sigurAccessPoint(state) {
        return state.sigurAccessPoint;
    },
    sigurPeriodSettings(state) {
        return state.sigurPeriodSettings;
    },
    pacsLogs(state) {
        return state.pacsLogs;
    },
    azureSyncStatus(state) {
        return state.azureSyncStatus;
    },
    azureMappingCalendarUsers(state) {
        state.azureMappingData.calendar_users.forEach(user => {
            user.isUse = false;
            return state.azureMappingData.related.forEach(aData => {
                if (user.id === aData.azure_user_id) user.isUse = true;
            })
        });
        return state.azureMappingData.calendar_users;
    },
    azureMappingMeetingRooms(state) {
        return state.azureMappingData.meeting_rooms;
    },
    azureMappingRelated(state) {
        return state.azureMappingData.related;
    },
    azureLogs(state) {
        return state.azureLogs;
    }
};

const actions = {
    // SIGUR
    [GET_ACCESS_TOKEN](context) {
        ApiService.get("management/integrations/pacs/sigur/setting/access-token/show")
            .then(({data}) => {
                context.commit(SET_ACCESS_TOKEN, data);
            })
            .catch(({response}) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [PUT_ACCESS_TOKEN](context, payload) {
        ApiService.put("management/integrations/pacs/sigur/setting/access-token/edit", payload.form)
            .then(({data}) => {
                context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.DATA_SAVED") );
                sendAmplitudeEvent('management-integrations-sigur-authorization save-token', { 'result': 'success' });
            })
            .catch(({response}) => {
                context.commit(SET_ERROR, response.data);
                sendAmplitudeEvent('management-integrations-sigur-authorization save-token', { 'result': 'error' });
            })
    },
    [GET_SIGUR_ACCESS_POINTS_LIST](context, payload) {
        ApiService.query("management/integrations/pacs/sigur/access-points/list", { params: { ...payload } })
            .then(({data}) => {
                context.commit(SET_SIGUR_ACCESS_POINTS_LIST, data);
            })
            .catch(({response}) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_SIGUR_POINT](context, payload) {
        return new Promise(resolve => {
            ApiService.get("management/integrations/pacs/sigur/access-point/" + payload)
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_SIGUR_POINT, data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [POST_NEW_SIGUR_POINT](context, payload) {
        return new Promise(resolve => {
            ApiService.post("management/integrations/pacs/sigur/access-point/add", payload)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.ACCESS_POINT_ADDED") );
                    sendAmplitudeEvent('management-integrations-sigur-point add', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-integrations-sigur-point add', { 'result': 'error' });
                });
        });
    },
    [PUT_SIGUR_POINT](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/integrations/pacs/sigur/access-point/${payload.id}/edit`, payload.form)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.ACCESS_POINT_EDITED") );
                    sendAmplitudeEvent('management-integrations-sigur-point edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-integrations-sigur-point edit', { 'result': 'error' });
                });
        });
    },
    [DELETE_SIGUR_POINT](context, payload) {
        return new Promise(resolve => {
            ApiService.delete(`/management/integrations/pacs/sigur/access-point/${payload}/remove`)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.ACCESS_POINT_REMOVED") );
                    sendAmplitudeEvent('management-integrations-sigur-point delete', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-integrations-sigur-point delete', { 'result': 'error' });
                });
        });
    },
    [ADD_ACCESS_POINT_GROUP](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/integrations/pacs/sigur/access-point/${payload.id}/add/groups`, payload.body)
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.GROUP_ADDED") );
                    sendAmplitudeEvent('management-integrations-sigur-point-group add', { 'result': 'success' });
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-integrations-sigur-point-group add', { 'result': 'error' });
                })
        });
    },
    [REMOVE_ACCESS_POINT_GROUP](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/integrations/pacs/sigur/access-point/${payload.id}/remove/group`, payload.body)
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.GROUP_REMOVED") );
                    sendAmplitudeEvent('management-integrations-sigur-point-group delete', { 'result': 'success' });
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-integrations-sigur-point-group delete', { 'result': 'error' });
                })
        });
    },
    [GET_SIGUR_PERIOD_SETTINGS](context) {
        ApiService.get("management/integrations/pacs/sigur/setting/check-period/show")
            .then(({data}) => {
                context.commit(SET_SIGUR_PERIOD_SETTINGS, data);
            })
            .catch(({response}) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [PUT_SIGUR_PERIOD_SETTINGS](context, payload) {
        ApiService.put("management/integrations/pacs/sigur/setting/check-period/edit", payload.form)
            .then(({data}) => {
                context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.DATA_SAVED") );
                sendAmplitudeEvent('management-integrations-sigur-authorization check-period', { 'result': 'success' });
            })
            .catch(({response}) => {
                context.commit(SET_ERROR, response.data);
                sendAmplitudeEvent('management-integrations-sigur-authorization check-period', { 'result': 'error' });
            })
    },
    [GET_PACS_LOGS](context, payload) {
        ApiService.query("management/integrations/pacs/logs/list", { params: payload })
            .then(({data}) => {
                context.commit(SET_PACS_LOGS, data);
            })
            .catch(({response}) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    // AZURE
    [GET_AZURE_SYNC_STATUS](context) {
        ApiService.get("management/integrations/azure/settings/sync/status")
            .then(({data}) => {
                context.commit(SET_AZURE_SYNC_STATUS, data);
            })
            .catch(({response}) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_AZURE_AUTH](context) {
        return new Promise(resolve => {
            ApiService.get("management/integrations/azure/auth")
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [GET_AZURE_CHECK_SYNC](context, payload) {
        return new Promise(resolve => {
            ApiService.query("management/integrations/azure/auth/check", { params: payload })
                .then(({data}) => {
                    resolve(data);
                    sendAmplitudeEvent('management-integrations-azure-authorization change-status', { 'result': 'success', 'status': 'enabled' });
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-integrations-azure-authorization change-status', { 'result': 'error', 'status': 'enabled' });
                })
        });
    },
    [PUT_AZURE_CANCEL_SYNC](context) {
        return new Promise(resolve => {
            ApiService.put("management/integrations/azure/settings/sync/cancel")
                .then(({data}) => {
                    resolve(data);
                    sendAmplitudeEvent('management-integrations-azure-authorization change-status', { 'result': 'success', 'status': 'disabled' });
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-integrations-azure-authorization change-status', { 'result': 'error', 'status': 'disabled' });
                })
        });
    },
    [GET_AZURE_MAPPING_DATA](context) {
        ApiService.get("management/integrations/azure/settings/mapping/show/meeting-rooms/data")
            .then(({data}) => {
                context.commit(SET_AZURE_MAPPING_DATA, data);
            })
            .catch(({response}) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [POST_AZURE_MAPPING_DATA](context, payload) {
        ApiService.post("management/integrations/azure/settings/mapping/edit/meeting-room", payload)
            .then(() => {
                context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.DATA_SAVED") );
                sendAmplitudeEvent('management-integrations-azure-sync edit', { 'result': 'success' });
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
                sendAmplitudeEvent('management-integrations-azure-sync edit', { 'result': 'error' });
            });
    },
    [GET_AZURE_LOGS](context, payload) {
        ApiService.query("management/integrations/azure/logs/list", { params: payload })
            .then(({data}) => {
                context.commit(SET_AZURE_LOGS, data);
            })
            .catch(({response}) => {
                context.commit(SET_ERROR, response.data);
            })
    }
};

const mutations = {
    [SET_ACCESS_TOKEN](state, data) {
        state.sigurAccessToken = data;
    },
    [SET_SIGUR_ACCESS_POINTS_LIST](state, data) {
        state.sigurAccessPoints = data;
    },
    [SET_SIGUR_POINT](state, data) {
        state.sigurAccessPoint = data;
    },
    [SET_SIGUR_PERIOD_SETTINGS](state, data) {
        state.sigurPeriodSettings = data;
    },
    [SET_PACS_LOGS](state, data) {
        state.pacsLogs = data;
    },
    [SET_AZURE_SYNC_STATUS](state, data) {
        state.azureSyncStatus = data;
    },
    [SET_AZURE_MAPPING_DATA](state, data) {
        state.azureMappingData = data;
    },
    [SET_AZURE_LOGS](state, data) {
        state.azureLogs = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
