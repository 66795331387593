// ENGLISH
export const locale = {
    TRANSLATOR: {
        SELECT: "Select your language"
    },
    MENU: {
        MAIN_MENU: "Main Menu",
        MY_OFFICE: "My office",
        BOOKING_CALENDAR: "Booking calendar",
        // COLLEAGUES: "Colleagues",
        MANAGEMENT: "Management",
        ANALYTICS: "Analytics"
    },
    WEEK_DAYS: {
        MONDAY: "Monday",
        TUESDAY: "Tuesday",
        WEDNESDAY: "Wednesday",
        THURSDAY: "Thursday",
        FRIDAY: "Friday",
        SATURDAY: "Saturday",
        SUNDAY: "Sunday",
        MON: "Mon",
        TUE: "Tue",
        WED: "Wed",
        THU: "Thu",
        FRI: "Fri",
        SAT: "Sat",
        SUN: "Sun",
    },
    AUTH: {
        GENERAL: {
            TITLE: "Seating management system",
            DESC: "Book workplaces and meeting rooms in your office in one click.",
            // SUBMIT_BUTTON: "Submit",
            // NO_ACCOUNT: "Don't have an account?",
            // SIGNUP_BUTTON: "Sign Up",
            // FORGOT_BUTTON: "Forgot Password",
            // BACK_BUTTON: "Back",
        },
        LOGIN: {
            TITLE: "Authorization",
            DESC: "Sign-in to continue your work.",
            LOGIN_MICROSOFT_BUTTON: "Sign-in with your Microsoft account",
            LOGIN_MICROSOFT_STARTED_BUTTON: "Logging into your account...",
            REMEMBER_ME: "Remember me",
            BUTTON: "Sign In",
            LOCAL_AUTH: "Local Authorization",
            MICROSOFT_AUTH: "Microsoft account Authorization"
        },
        LOGOUT: {
            BUTTON: "Sign Out"
        },
        INPUT: {
            EMAIL_LABEL: "Email:",
            EMAIL_PLACEHOLDER: "Your email...",
            PASSWORD_LABEL: "Password:",
            PASSWORD_PLACEHOLDER: "Your password...",
        },
        VALIDATION: {
            INVALID: "{{name}} is not valid",
            REQUIRED: "{{name}} is required",
            MIN_LENGTH: "{{name}} minimum length is {{min}}",
            AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
            NOT_FOUND: "The requested {{name}} is not found",
            INVALID_LOGIN: "The login detail is incorrect",
            REQUIRED_FIELD: "Required field",
            MIN_LENGTH_FIELD: "Minimum field length:",
            MAX_LENGTH_FIELD: "Maximum field length:",
            INVALID_FIELD: "Field is not valid"
        }
        // FORGOT: {
        //     TITLE: "Forgot Password?",
        //     DESC: "Enter your email to reset your password",
        //     SUCCESS: "Your account has been successfully reset."
        // },
        // REGISTER: {
        //     TITLE: "Sign Up",
        //     DESC: "Enter your details to create your account",
        //     SUCCESS: "Your account has been successfuly registered."
        // },
    },
    SELECT: {
        DEFAULT_TEXT: "Select from the list...",
        COUNTRY_PLACEHOLDER: "Country...",
        CITY_PLACEHOLDER: "City...",
        OFFICE_PLACEHOLDER: "Office...",
        FLOOR_PLACEHOLDER: "Floor...",
        CLEAR_FIELD: "Clear field",
        SEARCH: "Search...",
        NOT_FOUND: "No results found...",
        SEVEN_DAYS: "Last 7 days",
        FOURTEEN_DAYS: "Last 14 days",
        THIRTY_DAYS: "Last 30 days",
        THIS_MONTH: "Current month",
        PREV_MONTH: "Previous month",
        MANUAL_SELECT: "Manual period selection",
        REPEAT_BY_DAYS: "Repeat by day",
        REPEAT_BY_WEEKS: "Repeat by week",
    },
    TITLE: {
        ROLES_TITLE: "Roles:",
        STATUSES_TITLE: "Statuses:",
        GROUPS_TITLE: "Groups:",
        EMPLOYEES_TITLE: "Employees:",
        STATUS_TITLE: "Status:",
        APPROVAL_STATUS_TITLE: "Approval status:",
        ALL_FILTER_SELECT: "All",
        AND_SELECT: "and ",
        MORE_SELECT: " more"
    },
    TABLE: {
        EMPTY_TITLE: "Nothing was found matching the given parameters.",
    },
    PAGINATION: {
        ROWS_SHOWN: "Show rows",
        FROM: "from"
    },
    FOOTER: {
        SYSTEM_PATH_1: " - Seating Management System ",
        SYSTEM_PATH_2: ". All Right Reserved",
        PRIVACY_POLITICS: "Privacy Policy"
    },
    MY_OFFICE: {
        TIME_FROM: "From:",
        TIME_TO: "To:",
        DROPDOWN_SEARCH_PLACEHOLDER: "The name of the desk, meeting room, the name of a colleague...",
        DUMMY_TITLE: "Choose a space to work",
        DUMMY_DESC: "To book a desk or meeting room, you must choose a space:",
        DESKS_BUTTON: "Desks",
        DESKS_AVAILABLE_BUTTON: "available",
        MEETING_ROOMS_BUTTON: "Meeting rooms",
        DESKS_TITLE: "Desks",
        MEETING_ROOMS_TITLE: "Meeting rooms",
        COLLEAGUES_TITLE: "Colleagues",
        AMENITIES_NOT_SELECTED: "Amenities not selected",
        AMENITIES: "Amenities: ",
        CLEAR_BUTTON: "Clear",
        ACCEPT_BUTTON: "Accept",
        AVAILABLE_FOR_BOOKING: "Available for booking:",
        AMOUNT_OF_SEATS_TO: "up to ",
        AMOUNT_OF_SEATS_PEOPLE: " people",
        AMOUNT_OF_SEATS_NOT_FOUND: "not specified",
        AMOUNT_OF_SEATS_IS_NOT_FOUND: "Amount of seats is not specified",
        BOOKED_TITLE: "Booked",
        ALL_DAY_TITLE: "All day",
        BOOKINGS_COUNT_TITLE: "Bookings: ",
        UNAVAILABLE_BOOKING_DESK_TITLE: "This workplace is not available for booking",
        UNAVAILABLE_BOOKING_MEETING_ROOM_TITLE: "This meeting room is not available for booking",
        NEW_BOOKING_BUTTON: "New booking",
        BOOK_NOW_BUTTON: "Book now",
        CANCEL_BOOKING_BUTTON: "Cancel booking",
        CANCEL_BOOKING_BTN: "Cancel booking",
        BACK_BUTTON: "Back",
        ALL_BOOKINGS: "All bookings",
        NO_BOOKINGS_YET: "There are no bookings for today.",
        BOOKING_TITLE: "Booking: ",
        MEMBERS_TITLE: " members",
        LOCAL_TIME_DESC: "Office time is indicated in the local time zone",
        TIME_TITLE: "Time",
        IN_LOCAL_TIME_DESC: "In the local time of the office",
        MEMBERS_BLOCK_TITLE: "Members",
        MEMBERS_BLOCK_SEARCH_PLACEHOLDER: "Start typing name or email...",
        MEMBERS_BLOCK_ADD: "Add",
        MEMBERS_BLOCK_AS_GUEST: "as guest",
        MEMBERS_BLOCK_GUEST_DESC: "Guest",
        MEMBERS_BLOCK_ORG_DESC: "Organizer",
        FREE_DESK_POINT_TYPE: "Free desk",
        FREE_MEET_POINT_TYPE: "Free meeting room",
        BOOKED_DESK_POINT_TYPE: "Booked desk",
        BOOKED_MEET_POINT_TYPE: "Booked meeting room",
        BUSY_DESK_POINT_TYPE: "Busy desk",
        BUSY_MEET_POINT_TYPE: "Busy meeting room",
        UNAVAILABLE_DESK_POINT_TYPE: "Unavailable desk",
        UNAVAILABLE_MEET_POINT_TYPE: "Unavailable meeting room"
    },
    BOOKING_CALENDAR: {
        TODAY_FILTER: "Today",
        DESKS_TAB: "Desks",
        MEETING_ROOMS: "Meeting rooms",
        EXPORT_BUTTON: "Export",
        NEW_BOOKING_BUTTON: "New booking",
        DAY_FILTER_SELECT: "Day",
        WEEK_FILTER_SELECT: "Week",
        MONTH_FILTER_SELECT: "Month",
        YES_FILTER_SELECT: "Yes",
        NO_FILTER_SELECT: "No",
        AGREED_TOOLTIP_TEXT: "Agreed bookings cannot be edited",
        SHOW_ALL_LINK_TEXT: "Show all...",
        ADD_DESK_RESERVE_TITLE: "Booking a desk",
        EDIT_DESK_RESERVE_TITLE: "Editing a desk reservation",
        DESK_INVALID_FEEDBACK: "Desk field is required",
        DESK_SELECT_TITLE: "Desk:",
        DATES_INVALID_FEEDBACK: "Date fields are required",
        DATES_SELECT_TITLE: "Time and date:",
        REPEAT_CHECKBOX_TEXT: "To repeat",
        EACH_TEXT: "Each",
        DAY_TEXT: "day",
        EVERY_WORK_DAY: "Every working day",
        EVERY_TEXT: "Every",
        WEEK_BY_DAY: "week, by day:",
        EMPLOYEE_INVALID_FEEDBACK: "Employee field is required",
        EMPLOYEE_SELECT_TITLE: "Employee:",
        AGREED_CHECKBOX_TEXT: "Mark as agreed",
        AGREED_WARNING_TEXT: "Agreed bookings cannot be edited.",
        CANCEL_BUTTON: "Cancel",
        CANCEL_BOOKING_BUTTON: "Cancel booking",
        SAVE_BUTTON: "Save",
        ADD_BUTTON: "Add"
    },
    MANAGEMENT: {
        SPACE_MANAGEMENT_TITLE: "Space management",
        OFFICES_TITLE: "Offices",
        AMENITIES_TITLE: "Amenities",
        USER_MANAGEMENT_TITLE: "User management",
        EMPLOYEES_TITLE: "Employees",
        GROUPS_TITLE: "Groups",
        ROLES_TITLE: "Roles",
        INTEGRATIONS_TITLE: "Integrations",
        OTHER_TITLE: "Other",
        ORGANIZATION_TITLE: "Organization",
        PRODUCTION_CALENDAR_TITLE: "Production calendar",
        // OFFICES
        OFFICES_TEXT_DESC: "You can add and configure office buildings for your organization in this section.",
        ADD_OFFICE_BUTTON: "Add office",
        NO_OFFICE_YET_TITLE: "Offices not added yet",
        NO_OFFICE_YET_TITLE_DESC: "Add your organization's offices to continue working:",
        EDIT_TOOLTIP_TEXT: "Edit",
        SETTINGS_FLOORS_TOOLTIP_TEXT: "Settings floors",
        CUSTOM_SPACE_PLAN_TOOLTIP_TEXT: "Customize space plan",
        RESOURCES_TOOLTIP_TEXT: "Resources",
        DELETE_TOOLTIP_TEXT: "Delete",
        DELETE_OFFICE_TITLE: "Delete office",
        DELETE_OFFICE_DESC: "Are you sure you want to delete the office?",
        CANCEL_BUTTON: "Cancel",
        DELETE_BUTTON: "Delete",
        NAME_TABLE_TITLE: "Name",
        FLOOR_TABLE_TITLE: "Floor",
        ADDRESS_TABLE_TITLE: "Address",
        STATUS_TABLE_TITLE: "Status",
        ACTIONS_TABLE_TITLE: "Actions",
        ADD_OFFICE_TITLE: "Add office",
        EDIT_OFFICE_TITLE: "Edit office",
        NAME_OFFICE_INVALID_FEEDBACK: "Office name is required",
        NAME_OFFICE_INPUT_TITLE: "Office name:",
        NAME_OFFICE_PLACEHOLDER: "Choose the name of the new office...",
        COUNTRY_INVALID_FEEDBACK: "Country field is required",
        COUNTRY_SELECT_TITLE: "Country:",
        CITY_INVALID_FEEDBACK: "City field is required",
        CITY_SELECT_TITLE: "City:",
        ADDRESS_INVALID_FEEDBACK: "Address is required",
        ADDRESS_INPUT_TITLE: "Address:",
        ADDRESS_PLACEHOLDER: "For example, 'pl. Revolution, 23 building 3'",
        TIMEZONE_INVALID_FEEDBACK: "The timezone field is required",
        TIMEZONE_SELECT_TITLE: "Timezone:",
        ADD_SETTING_FLOOR: "Add and configure floors",
        SAVE_BUTTON: "Save",
        ADD_BUTTON: "Add",
        EDIT_BUTTON: "Edit",
        FLOORS_TITLE: "Floors",
        FLOORS_TEXT_DESC: "You can add floors for an office building and place workspaces on them.",
        ADD_FLOOR_BUTTON: "Add floor",
        NO_FLOOR_YET_TITLE: "Floors not added yet",
        NO_FLOOR_YET_TITLE_DESC: "Add floors of this office to continue working:",
        FLOORS_TABLE_TITLE: "Floors",
        ADD_FLOOR_TITLE: "Add floor",
        EDIT_FLOOR_TITLE: "Edit floor",
        FLOOR_INVALID_FEEDBACK: "Floor name is required",
        FLOOR_INPUT_TITLE: "Floor name:",
        FLOOR_PLACEHOLDER: "Select a name for the new floor, for example 'Floor 21'...",
        ADD_SETTING_BUTTON: "Add and customize",
        DELETE_FLOOR_TITLE: "Delete floor",
        DELETE_FLOOR_DESC: "Are you sure you want to delete the floor?",
        NO_FLOOR_PLAN_YET_TITLE: "Floor plan not loaded yet",
        NO_FLOOR_PLAN_YET_TITLE_DESC: "Drag and drop or select an SVG file to upload:",
        NO_FLOOR_PLAN_YET_BUTTON: "Select a file...",
        RESET_BUTTON: "Reset",
        SET_UP_DESKS_BUTTON: "Set up Desks",
        SET_UP_MEETINGS_ROOMS_BUTTON: "Set up Meeting Rooms",
        CHANGE_FLOOR_PLAN_BUTTON: "Change floor plan",
        FLOOR_PLAN_WARNING_TEXT: "You cannot upload files of this type, only SVG",
        NOT_ASSIGNED_TITLE: "Not Assigned",
        DESK_TITLE: "Desk",
        MEETING_ROOM_TITLE: "Meeting room",
        FLOOR_PLAN_EDIT_TITLE: "Floor plan editor",
        CLOSE_FLOOR_PLAN_EDIT_BUTTON: "Close editor",
        EXIT_FLOOR_PLAN_EDIT_DESC: "Are you sure you want to leave the page? You have unsaved changes.",
        EXIT_BUTTON: "Exit",
        SETTING_UP_FLOOR_PLAN_TITLE: "Setting up the floor plan",
        RESOURCE_NOT_ASSIGNED_TITLE: "Resource not assigned",
        DESKS_TITLE: "Desks",
        MEETINGS_ROOMS_TITLE: "Meeting rooms",
        RESOURCE_TYPE_SELECT_TITLE: "Resource type:",
        RESOURCE_TYPE_PLACEHOLDER: "Resource type",
        DESK_SELECT_TITLE: "Desk:",
        DESK_PLACEHOLDER: "Desk",
        MEET_ROOM_SELECT_TITLE: "Meeting room:",
        MEET_ROOM_PLACEHOLDER: "Meeting room",
        AMENITIES_SELECT_TITLE: "Amenities:",
        AMENITIES_PLACEHOLDER: "Add amenities",
        FLOOR_OCCUPANCY_LIMIT_TITLE: "Floor occupancy limit",
        FLOOR_OCCUPANCY_LIMIT_DESC: "Set a limit on the simultaneous use of workplaces on the floor.",
        LIMIT_BY_PC: "PC.",
        DESKS_AVAILABLE_TITLE: "works places available for booking",
        SAVE_LIMIT_BUTTON: "Save limit",
        WARNING_TEXT_1: "Add workplaces and meeting rooms to the plan.",
        WARNING_TEXT_2: "You can customize the resource by clicking on the corresponding dot.",
        WARNING_TEXT_3: "Don't forget to save your changes after editing.",
        NO_PLAN_YET_TITLE: "The plan for this floor has not yet been uploaded",
        NO_PLAN_YET_TITLE_DESC: "To set up a workspace, download the plan file for this floor:",
        DOWNLOAD_PLAN_BUTTON: "Download Plan",
        DESK_POINT_TYPE: "Desk",
        MEET_ROOM_POINT_TYPE: "Meeting room",
        RESOURCES_TITLE: "Resources",
        RESOURCES_DESKS_TITLE: "Desks",
        RESOURCES_MEET_ROOMS_TITLE: "Meeting rooms",
        RESOURCES_DESKS_TITLE_DESC: "You can add desk to place them on the floor plans.",
        RESOURCES_MEET_ROOMS_TITLE_DESC: "You can add meeting rooms to place them on the floor plans.",
        ADD_DESK_BUTTON: "Add desk",
        NO_DESKS_YET_TITLE: "Desks not added yet",
        NO_DESKS_YET_DESC: "Add new desks to place them on the plan:",
        ADD_MEET_ROOM_BUTTON: "Add meeting room",
        NO_MEET_ROOMS_YET_TITLE: "Meeting rooms not added yet",
        NO_MEET_ROOMS_YET_DESC: "Add new meeting rooms to place them on the plan:",
        AV_STATUS_TYPE: "Available",
        UN_STATUS_TYPE: "Not available",
        ADD_DESK_TITLE: "Add desk",
        EDIT_DESK_TITLE: "Edit desk",
        DELETE_DESK_TITLE: "Delete desk",
        DELETE_DESK_DESC: "Are you sure you want to delete this desk?",
        ADD_MEET_ROOM_TITLE: "Add meeting room",
        EDIT_MEET_ROOM_TITLE: "Edit meeting room",
        DELETE_MEET_ROOM_TITLE: "Delete meeting room",
        DELETE_MEET_ROOM_DESC: "Are you sure you want to delete this meeting room?",
        NAME_DESK_INVALID_FEEDBACK: "Desk name is required",
        NAME_DESK_SELECT_TITLE: "Desk name:",
        NAME_DESK_PLACEHOLDER: "Enter the name of the new desk......",
        DESK_STATUS_INVALID_FEEDBACK: "Status field is required",
        DESK_STATUS_SELECT_TITLE: "Status:",
        RESOURCE_ACCESS_TITLE: "Resource access:",
        RESOURCE_ACCESS_PLACEHOLDER: "Start typing the group name...",
        NO_RESOURCE_YET_TITLE: "At the moment, the resource is available to everyone. You can restrict access to a resource by adding the necessary employee groups.",
        RESOURCE_ACCESS_LIST_TITLE: "The resource is available for groups:",
        NAME_MEET_ROOM_INVALID_FEEDBACK: "Meeting room name is required",
        NAME_MEET_ROOM_SELECT_TITLE: "Meeting room name:",
        NAME_MEET_ROOM_PLACEHOLDER: "Enter a name for the new meeting room...",
        MEET_ROOM_STATUS_INVALID_FEEDBACK: "Status field is required",
        MEET_ROOM_STATUS_SELECT_TITLE: "Status:",
        SQUARE_INPUT_TITLE: "Square:",
        SQUARE_PLACEHOLDER: "Enter area value...",
        CAPACITY_INPUT_TITLE: "Capacity:",
        CAPACITY_INPUT_PLACEHOLDER: "Enter a capacity value...",
        PEOPLE_TITLE: "people",
        // AMENITIES:
        AMENITIES: "Amenities",
        AMENITIES_DESC: "You can add amenities and attach them to desks and meeting rooms.",
        ADD_AMENITIES: "Add amenities",
        AMENITIES_NOT_YET_TITLE: "Amenities not added yet",
        AMENITIES_NOT_YET_DESC: "Add amenities to attach them to desks and meeting rooms:",
        DESKS_TABLE_TITLE: "Desks",
        MEET_ROOMS_TABLE_TITLE: "Meeting rooms",
        ADD_AMENITIES_TITLE: "Add amenity",
        EDIT_AMENITIES_TITLE: "Edit amenity",
        NAME_AMENITY_INVALID_FEEDBACK: "Amenity name is required",
        NAME_AMENITY_INPUT_TITLE: "Name:",
        NAME_AMENITY_INPUT_PLACEHOLDER: "Choose the name of the new amenity...",
        DESK_AVAILABLE_CHECKBOX_TITLE: "Available for desks:",
        MEET_ROOM_AVAILABLE_CHECKBOX_TITLE: "Available for meeting rooms:",
        DELETE_AMENITIES_TITLE: "Delete amenity",
        DELETE_AMENITIES_DESC: "Are you sure you want to remove amenity? It will disappear from the search filters.",
        // EMPLOYEES
        EMPLOYEES: "Employees",
        EMPLOYEES_DESC: "Employees in your organization:",
        EMPLOYEE_ACTIVE_STATUS: "Active",
        EMPLOYEE_WAIT_STATUS: "Wait",
        EMPLOYEE_BLOCKED_STATUS: "Blocked",
        LAST_NAME_TABLE_TITLE: "Name",
        ROLES_TABLE_TITLE: "Roles",
        GROUPS_TABLE_TITLE: "Groups",
        EMPLOYEE_PROFILE: "Employee profile",
        PERSONAL_DATA: "Personal data",
        LAST_NAME_TITLE: "Last name:",
        FIRST_NAME_TITLE: "First name:",
        PATRONYMIC_TITLE: "Patronymic:",
        JOB_TITLE: "Job title:",
        PHONE_NUMBER_TITLE: "Phone number:",
        MASTER_SYSTEM: "Master system:",
        EMPLOYEE_GROUPS: "User groups:",
        EMPLOYEE_ROLES: "User roles:",
        ACS_TITLE: "ACS",
        ACS_CARD_NUMBER_TITLE: "ACS card number:",
        EMPLOYEE_ACS_CARD_TITLE: "Employee's access control card",
        ACS_CARD_INPUT_INVALID_FEEDBACK: "Card number is required",
        ACS_CARD_INPUT_TITLE: "Card number:",
        ACS_CARD_INPUT_PLACEHOLDER: "Enter the ACS card number...",
        ACS_CARD_INPUT_DESC: "You can add the ACS card number through the reader. To do this, click on the input field and bring the card to the reader.",
        // GROUPS
        GROUPS: "Groups",
        GROUPS_DESC: "Groups in your organization:",
        MEMBER_COUNT_TABLE_TITLE: "Number of members",
        IN_REPORTS_TABLE_TITLE: "Available in reports",
        IN_REPORTS_TABLE_DESC: "The group will be displayed in the \"Employee Attendance\" and \"Group Attendance\" reports, as well as in the booking calendar",
        GROUPS_NOT_YET_TITLE: "There are no groups yet in your organization",
        GROUPS_NOT_YET_DESC: "Adding user groups is possible through synchronization with an external user accounting system.",
        GROUP_SETTING_TOOLTIP_TEXT: "Settings",
        GROUP_EDIT_TITLE: "Group editing",
        GROUP_SETTING_NAME: "Group name:",
        EMPTY_GROUP_MEMBERS_TABLE: "The group members table is empty.",
        MEMBERS_IN_GROUP_TITLE: "Members of the group",
        MEMBERS_IN_GROUP_TOTAL: "Members in the group:",
        // ROLES
        ROLES: "Roles",
        ROLES_DESC: "Roles allow you to define the permissions and access levels that users need to use Deskki.",
        DEFAULT_LABEL: "Default",
        ROLE_TABLE_TITLE: "Role",
        TYPE_TABLE_TITLE: "Type",
        // ORGANIZATION
        ORGANIZATION: "Organization",
        ORGANIZATION_DESC: "You can set up organization data that will be available to users.",
        ORGANIZATION_INPUT_INVALID_FEEDBACK: "Organization name is required",
        ORGANIZATION_INPUT_TITLE: "Name:",
        ORGANIZATION_INPUT_PLACEHOLDER: "Enter the name of the organization...",
        LOGO_INPUT_TITLE: "Logo:",
        LOGO_INPUT_DESC: "Image format PNG, JPG, JPEG, SVG no larger than 200 KB.",
        LOGO_INPUT_WARNING: "File over 200 Kb",
        // PRODUCTION CALENDAR
        PRODUCTION_CALENDAR_DESC: "You can set up working days and holidays for your organization in this section.",
        WORKING_TIME_PARAMS: "Working time parameters",
        OFF_DAYS_OF_WEEK: "Days off of the week",
        WORKING_HOURS_TITLE: "Working hours",
        WEEKENDS_AND_HOLIDAYS: "Weekends and public holidays throughout the year",
        WEEKENDS_AND_HOLIDAYS_TOOLTIP: "In this field, you can specify state and corporate holidays, non-working days by decree of the President",
        WEEKENDS_AND_HOLIDAYS_PLACEHOLDER: "Enter in the format dd.mm.yyyy",
        WEEKENDS_AND_HOLIDAYS_ADD_BTN: "ADD",
        APPLY_BUTTON: "Apply",
        // SIGUR
        AUTHORIZATION_TITLE: "Authorization",
        AUTHORIZATION_DESC: "Enter the authorization data of the Sigur ACS administrator to enable synchronization with Deskki.",
        TOKEN_INPUT_INVALID_FEEDBACK: "Token is required",
        TOKEN_INPUT_TITLE: "Token:",
        TOKEN_INPUT_PLACEHOLDER: "Enter token...",
        ACCESS_POINTS_TITLE: "Access points",
        ACCESS_POINTS_DESC: "Add access points from Sigur to Deskki to provide access control for groups of employees.",
        ADD_ACCESS_POINTS_BUTTON: "Add access point",
        SETTING_ACCESS_TOOLTIP_TEXT: "Set up access",
        ACCESS_POINTS_NOT_YET: "No access points have been added at the moment.",
        OFFICE_TABLE_TITLE: "Office",
        ADD_ACCESS_POINT_TITLE: "Add access point",
        EDIT_ACCESS_POINT_TITLE: "Edit access point",
        OFFICE_SELECT_INVALID_FEEDBACK: "Office field is required",
        OFFICE_SELECT_TITLE: "Office:",
        FLOOR_SELECT_INVALID_FEEDBACK: "The floor field is required",
        FLOOR_SELECT_TITLE: "Floor:",
        NAME_ACCESS_POINT_INPUT_INVALID_FEEDBACK: "Access point name is required",
        NAME_ACCESS_POINT_INPUT_TITLE: "Access point name:",
        NAME_ACCESS_POINT_INPUT_PLACEHOLDER: "Select access point name...",
        ID_ACCESS_POINT_INPUT_INVALID_FEEDBACK: "Access point ID in Sigur is required",
        ID_ACCESS_POINT_INPUT_TITLE: "Access point ID in Sigur:",
        ID_ACCESS_POINT_INPUT_PLACEHOLDER: "Select Access Point ID...",
        DELETE_ACCESS_POINT_TITLE: "Delete access point",
        DELETE_ACCESS_POINT_DESC: "Are you sure you want to delete the access point?",
        EMPLOYEE_GROUPS_TITLE: "Employee groups",
        EMPLOYEE_GROUPS_DESC: "Add employee groups for this point to provide access to it.",
        ADD_GROUP_BUTTON: "Add groups",
        EMPLOYEE_GROUPS_NOT_YET: "No group has been added for this access point at the moment.",
        GROUP_TABLE_TITLE: "Group",
        ADD_GROUPS_TITLE: "Add groups",
        GROUPS_SELECT_INVALID_FEEDBACK: "Groups field is required",
        GROUPS_SELECT_TITLE: "Select groups:",
        GROUPS_SELECT_PLACEHOLDER: "Start typing the group name...",
        GROUPS_SELECT_SEARCH: "Start typing to search...",
        DELETE_GROUPS_TITLE: "Delete groups",
        DELETE_GROUPS_DESC: "Are you sure you want to delete the group?",
        SETTINGS_TITLE: "Settings",
        PERIOD_INPUT_INVALID_FEEDBACK: "Period is required",
        PERIOD_INPUT_TITLE: "System health check period, seconds:",
        PERIOD_INPUT_PLACEHOLDER: "Enter period...",
        LOGS_TITLE: "Error",
        LOGS_DESC: "Sigur ACS synchronization errors are displayed here. Use the filter to specify the period.",
        PERIOD_TITLE: "Period:",
        DATE_TIME_TABLE_TITLE: "Date and time of the event",
        DESC_TABLE_TITLE: "Description",
        // AZURE
        AZURE_AUTHORIZATION_DESC: "To enable synchronization of users, groups and bookings, click on the \"Enable synchronization\" button and go through the authorization process as a system user. System user data can be found in the Microsoft Azure interface.",
        SYNC_ENABLED_TITLE: "Synchronization enabled",
        SYNC_DISABLED_TITLE: "Synchronization disabled",
        DISABLED_SYNC_BUTTON: "Disabled sync",
        ENABLED_SYNC_BUTTON: "Enabled sync",
        MAPPING_MEET_ROOMS_TITLE: "Mapping meeting rooms",
        MAPPING_MEET_ROOMS_DESC: "To match room bookings between Microsoft Azure and Deskki, you need to match the rooms with the respective accounts that are responsible for the booking in Azure.",
        MAPPING_MEET_ROOMS_NOT_YET: "No meeting rooms have been added at the moment.",
        MEET_ROOM_TABLE_TITLE: "Meeting room",
        MIC_AZ_ACCOUNT_TABLE_TITLE: "Microsoft Azure account",
        AZURE_LOGS_DESC: "Errors syncing with Microsoft Azure are displayed here. Use the filter to specify the period.",
    },
    ANALYTICS: {
        UTILIZATION_TITLE: "Utilization",
        DESKS_UTILIZATION_TITLE: "Desks utilization",
        MEETING_ROOMS_UTILIZATION_TITLE: "Meeting rooms utilization",
        OTHER_TITLE: "Other",
        OFFICE_ATTENDANCE_TITLE: "Office attendance",
        EMPLOYEE_ATTENDANCE_TITLE: "Employee attendance",
        GROUP_ATTENDANCE_TITLE: "Group attendance",
        WARNING_TEXT: "Select the floor to display the graph.",
        PERIOD_TITLE: "Period:",
        ACCESS_POINTS_TITLE: "Access points:",
        OFFICES_TITLE: "Offices:",
        FLO0RS_TITLE: "Floors:",
        DESKS_TITLE: "Desks:",
        MEETING_ROOMS_TITLE: "Meeting rooms:",
        WORKING_HOURS_TITLE: "Working hours",
        SETTINGS_BUTTON: "Settings",
        LIMIT_SET_TITLE: "Set limit:",
        LIMIT_SET_TOOLTIP_TEXT: "If enabled, the utilization will be calculated taking into account the floor occupancy limit",
        MANUAL_PERIOD_FILTER_SELECT: "Manual period selection",
        TIME_FROM: "From:",
        TIME_TO: "To:",
        EXPORT_BUTTON: "Export",
        CANCEL_BUTTON: "Cancel",
        RESET_BUTTON: "Reset",
        APPLY_BUTTON: "Apply",
        NO_WORK_DAYS: "Non-working day",
        TOTAL_VISITORS_POINT: "Total visitors",
        PLAN_TABLE_TITLE: "Plan",
        FACT_TABLE_TITLE: "Fact",
        GROUP_TABLE_TITLE: "Group",
        EMPLOYEE_TABLE_TITLE: "Employee",
        ASSIGMENT_TABLE_TITLE: "Assigment",
        EMPLOYEES_NUMBER_TABLE_TITLE: "Number of employees",
        QUOTA_TABLE_TITLE: "Quota"
    },
    SUCCESS_MESSAGES: {
        OFFICE_ADDED: "Office added successfully!",
        OFFICE_EDITED: "Office data changed successfully!",
        OFFICE_DELETED: "Office deleted successfully!",
        FLOOR_ADDED: "New floor added successfully!",
        FLOOR_EDITED: "Floor data has been successfully changed!",
        FLOOR_DELETED: "Floor successfully deleted!",
        FLOOR_LIMIT: "Floor limit successfully set!",
        SAVE_TEXT: "Saved successfully!",
        DELETE_TEXT: "Successfully removed!",
        FLOOR_PLAN_SAVE: "The floor plan has been successfully saved!",
        PACS_CARD_SAVE: "ACS card assigned successfully!",
        GROUP_EDITED: "Group data has been successfully changed!",
        BOOKING_CREATED: "Booking created successfully!",
        BOOKING_EDITED: "Booking changed successfully!",
        BOOKING_CANCELED: "Booking canceled successfully!",
        AMENITY_ADDED: "Amenity added successfully!",
        AMENITY_EDITED: "Amenity changed successfully!",
        AMENITY_DELETED: "Amenity deleted successfully!",
        DESK_BOOKED: "Desk successfully booked!",
        MEET_BOOKED: "Meeting room successfully booked!",
        MEMBER_ADDED: "Member successfully added!",
        MEMBER_DELETED: "Member successfully deleted!",
        ORG_SAVED: "Organization data successfully saved!",
        DATA_SAVED: "Data saved successfully!",
        ACCESS_POINT_ADDED: "Access point added successfully!",
        ACCESS_POINT_EDITED: "Access point changed successfully!",
        ACCESS_POINT_REMOVED: "Access point deleted successfully!",
        GROUP_ADDED: "Group added successfully!",
        GROUP_REMOVED: "Group deleted successfully!",
        DESK_ADDED: "Desk added successfully!",
        MEET_ADDED: "Meeting room added successfully!",
        CHANGED_ADDED: "Changes applied successfully!",
        RESOURCE_DELETED: "Resource deleted successfully!",
    },
    ERROR_MESSAGES: {
        TEXT: "An error has occurred. Please try again.",
    }
};
