import ApiService from "@/core/services/api.service";
import { SET_ERROR, SET_SUCCESS } from "@/core/services/store/auth.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";
import i18n from "../../../plugins/vue-i18n";

// action types
export const GET_AMENITIES_LIST = "getAmenitiesList";
export const POST_NEW_AMENITY = "postNewAmenity";
export const PUT_AMENITY = "putAmenity";
export const DELETE_AMENITY = "deleteAmenity";

// mutation types
export const SET_AMENITIES_LIST = "setAmenitiesList";

const state = {
    amenitiesList: {
        items: [],
        pagination: {}
    }
};

const getters = {
    amenitiesList(state) {
        return state.amenitiesList;
    }
};

const actions = {
    [GET_AMENITIES_LIST](context, payload) {
        ApiService.query("management/amenities/list", { params: {...payload} })
            .then(({ data }) => {
                context.commit(SET_AMENITIES_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            });
    },
    [POST_NEW_AMENITY](context, payload) {
        return new Promise(resolve => {
            ApiService.post("management/amenity/add", payload)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.AMENITY_ADDED") );
                    sendAmplitudeEvent('management-amenity add', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-amenity add', { 'result': 'error' });
                });
        });
    },
    [PUT_AMENITY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`management/amenity/${payload.id}/edit`, payload.form)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.AMENITY_EDITED") );
                    sendAmplitudeEvent('management-amenity edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-amenity edit', { 'result': 'error' });
                });
        })
    },
    [DELETE_AMENITY](context, payload) {
        return new Promise(resolve => {
            ApiService.delete(`/management/amenity/${payload}/remove`)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.AMENITY_DELETED") );
                    sendAmplitudeEvent('management-amenity delete', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-amenity delete', { 'result': 'error' });
                });
        });
    }
};

const mutations = {
    [SET_AMENITIES_LIST](state, data) {
        state.amenitiesList = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
