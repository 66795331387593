import ApiService from "@/core/services/api.service";
import { SET_ERROR, SET_SUCCESS } from "@/core/services/store/auth.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";
import i18n from "../../../plugins/vue-i18n";

// action types
export const GET_ACCESS_POINT_LIST = "getAccessPointList";
export const GET_ATTENDANCE_ACCESS_POINTS = "getAttendanceAccessPoints";
export const GET_ATTENDANCE_EMPLOYEES = "getAttendanceEmployees";
export const EXPORT_ATTENDANCE_EMPLOYEES = "exportAttendanceEmployees";
export const EXPORT_TO_EMAIL_ATTENDANCE_EMPLOYEES = "exportToEmailAttendanceEmployees";
export const GET_ATTENDANCE_GROUPS = "getAttendanceGroups";
export const EXPORT_ATTENDANCE_GROUPS = "exportAttendanceGroups";

// mutation types
export const SET_ACCESS_POINT_LIST = "setAccessPointList";
export const SET_ATTENDANCE_ACCESS_POINTS = "setAttendanceAccessPoints";
export const SET_ATTENDANCE_EMPLOYEES = "setAttendanceEmployees";
export const SET_ATTENDANCE_GROUPS = "setAttendanceGroups";

const state = {
    accessPointList: [],
    attendanceAccessPoints: [],
    attendanceEmployees: [],
    attendanceGroups: []
};

const getters = {
    accessPointList(state) {
        return state.accessPointList;
    },
    attendanceAccessPointsData(state) {
        return state.attendanceAccessPoints;
    },
    attendanceEmployees(state) {
        const entries = Object.entries(state.attendanceEmployees);
        let employees = [];
        entries.forEach(item => {
            if (item[0] !== 'total') {
                item.id = item[0];
                item.groupName = item[1].name;
                let total = [];
                Object.entries(item[1]).forEach(array => {
                    array.id = array[0];
                    array.name = array[1].name;
                    array.data = array[1].data;
                    delete array[0]; delete array[1];
                    if (array.id !== 'name') total.push(array);
                })
                item.data = total;
                delete item[0]; delete item[1];
                employees.push(item);
            } else {
                item.id = 1;
                item.name = i18n.t("ANALYTICS.EMPLOYEES_NUMBER_TABLE_TITLE");
                item._rowVariant = 'primary';
                let total = [];
                Object.entries(item[1]).forEach(array => {
                    array[1].date = array[0].toString();
                    total.push(array[1]);
                })
                item.data = total;
                delete item[0]; delete item[1];
                employees.unshift(item);
            }
        })
        return employees;
    },
    attendanceGroups(state) {
        const entries = Object.entries(state.attendanceGroups);
        let groups = [];
        if (entries.length > 0) {
            groups.push({ id: 1, name: '', data: entries[0], _rowVariant: 'primary' });
        }
        entries.forEach(item => {
            item.id = item[0];
            item.name = item[1].name;
            item.data = item[1].data;
            delete item[0]; delete item[1];
            groups.push(item);
        })
        return groups;
    }
};

const actions = {
    [GET_ACCESS_POINT_LIST](context, payload) {
        ApiService.get("management/integrations/pacs/sigur/access-points/list")
            .then(({ data }) => {
                context.commit(SET_ACCESS_POINT_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_ATTENDANCE_ACCESS_POINTS](context, payload) {
        ApiService.post("management/integrations/pacs/sigur/report/attendance-access-points", payload)
            .then(({ data }) => {
                context.commit(SET_ATTENDANCE_ACCESS_POINTS, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_ATTENDANCE_EMPLOYEES](context, payload) {
        return new Promise(resolve => {
            ApiService.post("management/employees/reports/attendance-employees", payload)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_ATTENDANCE_EMPLOYEES, data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [EXPORT_ATTENDANCE_EMPLOYEES](context, payload) {
        return new Promise(resolve => {
            ApiService.postWithQuery("management/employees/reports/attendance-employees/export", payload.form, {
                responseType: 'blob',
                headers: { 'Accept': 'application/vnd.ms-excel' }
            })
                .then(({data}) => {
                    resolve(data);
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `export-employees-file-${payload.form.from}-${payload.form.to}.xlsx`
                    );
                    document.body.appendChild(link);
                    link.click();
                    sendAmplitudeEvent('attendance-employees export', { 'result': 'success' });
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('attendance-employees export', { 'result': 'error' });
                });
        });
    },
    [EXPORT_TO_EMAIL_ATTENDANCE_EMPLOYEES](context, payload) {
        return new Promise(resolve => {
            ApiService.postWithQuery("management/employees/reports/attendance-employees/export-to-email", payload.form)
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, data.message );
                    sendAmplitudeEvent('attendance-employees export to email', { 'result': 'success' });
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('attendance-employees export to email', { 'result': 'error' });
                });
        });
    },
    [GET_ATTENDANCE_GROUPS](context, payload) {
        ApiService.post("management/groups/reports/attendance-groups", payload)
            .then(({ data }) => {
                context.commit(SET_ATTENDANCE_GROUPS, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [EXPORT_ATTENDANCE_GROUPS](state, payload) {
        return new Promise(resolve => {
            ApiService.postWithQuery("management/groups/reports/attendance-groups/export", payload.form, {
                responseType: 'blob',
                headers: { 'Accept': 'application/vnd.ms-excel' }
            })
                .then(({data}) => {
                    resolve(data);
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute(
                        'download',
                        `export-groups-file-${payload.form.from}-${payload.form.to}.xlsx`
                    );
                    document.body.appendChild(link);
                    link.click();
                    sendAmplitudeEvent('attendance-groups export', { 'result': 'success' });
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('attendance-groups export', { 'result': 'error' });
                });
        });
    }
};

const mutations = {
    [SET_ACCESS_POINT_LIST](state, data) {
        state.accessPointList = data;
    },
    [SET_ATTENDANCE_ACCESS_POINTS](state, data) {
        state.attendanceAccessPoints = data;

        // state.attendanceAccessPoints = [
        //     {
        //         value: 23,
        //         date: "06.08.2021",
        //         is_holiday: false
        //     },
        //     {
        //         value: 25,
        //         date: "07.08.2021",
        //         is_holiday: true
        //     },
        //     {
        //         value: 15,
        //         date: "08.08.2021",
        //         is_holiday: true
        //     },
        //     {
        //         value: 23,
        //         date: "09.08.2021",
        //         is_holiday: false
        //     },
        //     {
        //         value: 43,
        //         date: "10.08.2021",
        //         is_holiday: false
        //     },
        //     {
        //         value: 20,
        //         date: "11.08.2021",
        //         is_holiday: false
        //     },
        //     {
        //         value: 32,
        //         date: "12.08.2021",
        //         is_holiday: false
        //     }
        // ];
    },
    [SET_ATTENDANCE_EMPLOYEES](state, data) {
        state.attendanceEmployees = data;
    },
    [SET_ATTENDANCE_GROUPS](state, data) {
        state.attendanceGroups = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
