// RUSSIAN
export const locale = {
    TRANSLATOR: {
        SELECT: "Выберите свой язык"
    },
    MENU: {
        MAIN_MENU: "Главное меню",
        MY_OFFICE: "Мой офис",
        BOOKING_CALENDAR: "Календарь",
        // COLLEAGUES: "Мои коллеги",
        MANAGEMENT: "Управление",
        ANALYTICS: "Аналитика"
    },
    WEEK_DAYS: {
        MONDAY: "Понедельник",
        TUESDAY: "Вторник",
        WEDNESDAY: "Среда",
        THURSDAY: "Четверг",
        FRIDAY: "Пятница",
        SATURDAY: "Суббота",
        SUNDAY: "Воскресенье",
        MON: "Пн",
        TUE: "Вт",
        WED: "Ср",
        THU: "Чт",
        FRI: "Пт",
        SAT: "Сб",
        SUN: "Вс",
    },
    AUTH: {
        GENERAL: {
            TITLE: "Система управления рассадкой",
            DESC: "Бронируйте рабочие места и переговорные комнаты в вашем офисе в один клик.",
            // SUBMIT_BUTTON: "Submit",
            // NO_ACCOUNT: "Don't have an account?",
            // SIGNUP_BUTTON: "Sign Up",
            // FORGOT_BUTTON: "Forgot Password",
            // BACK_BUTTON: "Back",
        },
        LOGIN: {
            TITLE: "Авторизация",
            DESC: "Авторизуйтесь для продолжения работы.",
            LOGIN_MICROSOFT_BUTTON: "Войти через аккаунт Microsoft",
            LOGIN_MICROSOFT_STARTED_BUTTON: "Входим в аккаунт...",
            REMEMBER_ME: "Запомнить меня",
            BUTTON: "Войти",
            LOCAL_AUTH: "Локальная авторизация",
            MICROSOFT_AUTH: "Авторизация через аккаунт Microsoft"
        },
        LOGOUT: {
            BUTTON: "Выйти"
        },
        INPUT: {
            EMAIL_LABEL: "Email:",
            EMAIL_PLACEHOLDER: "Ваш email...",
            PASSWORD_LABEL: "Пароль:",
            PASSWORD_PLACEHOLDER: "Ваш пароль...",
        },
        VALIDATION: {
            INVALID: "{{name}} is not valid",
            REQUIRED: "{{name}} is required",
            MIN_LENGTH: "{{name}} minimum length is {{min}}",
            AGREEMENT_REQUIRED: "Accepting terms & conditions are required",
            NOT_FOUND: "The requested {{name}} is not found",
            INVALID_LOGIN: "The login detail is incorrect",
            REQUIRED_FIELD: "Required field",
            MIN_LENGTH_FIELD: "Minimum field length:",
            MAX_LENGTH_FIELD: "Maximum field length:",
            INVALID_FIELD: "Field is not valid"
        }
        // FORGOT: {
        //     TITLE: "Forgot Password?",
        //     DESC: "Enter your email to reset your password",
        //     SUCCESS: "Your account has been successfully reset."
        // },
        // REGISTER: {
        //     TITLE: "Sign Up",
        //     DESC: "Enter your details to create your account",
        //     SUCCESS: "Your account has been successfuly registered."
        // },
    },
    SELECT: {
        DEFAULT_TEXT: "Выберите из списка...",
        COUNTRY_PLACEHOLDER: "Страна...",
        CITY_PLACEHOLDER: "Город...",
        OFFICE_PLACEHOLDER: "Офис...",
        FLOOR_PLACEHOLDER: "Этаж...",
        CLEAR_FIELD: "Очистить поле",
        SEARCH: "Поиск...",
        NOT_FOUND: "Ничего не найдено",
        SEVEN_DAYS: "Последние 7 дней",
        FOURTEEN_DAYS: "Последние 14 дней",
        THIRTY_DAYS: "Последние 30 дней",
        THIS_MONTH: "Текущий месяц",
        PREV_MONTH: "Предыдущий месяц",
        MANUAL_SELECT: "Ручной выбор периода",
        REPEAT_BY_DAYS: "Повторять по дням",
        REPEAT_BY_WEEKS: "Повторять по неделям",
    },
    TITLE: {
        ROLES_TITLE: "Роли:",
        STATUSES_TITLE: "Статусы:",
        GROUPS_TITLE: "Группы:",
        EMPLOYEES_TITLE: "Сотрудники:",
        STATUS_TITLE: "Статус:",
        APPROVAL_STATUS_TITLE: "Статус согласования:",
        ALL_FILTER_SELECT: "Все",
        AND_SELECT: "и ",
        MORE_SELECT: " ещё"
    },
    TABLE: {
        EMPTY_TITLE: "По заданным параметрам ничего не найдено.",
    },
    PAGINATION: {
        ROWS_SHOWN: "Показывается строк",
        FROM: "из"
    },
    FOOTER: {
        SYSTEM_PATH_1: " - Система управления рассадкой ",
        SYSTEM_PATH_2: ". Все права защищены",
        PRIVACY_POLITICS: "Политика конфиденциальности"
    },
    MY_OFFICE: {
        TIME_FROM: "От:",
        TIME_TO: "До:",
        DROPDOWN_SEARCH_PLACEHOLDER: "Название рабочего места, переговорной, имя коллеги...",
        DUMMY_TITLE: "Выберите пространство для работы",
        DUMMY_DESC: "Для бронирования рабочего места или переговорной необходимо выбрать пространство:",
        DESKS_BUTTON: "Рабочие места",
        DESKS_AVAILABLE_BUTTON: "доступно",
        MEETING_ROOMS_BUTTON: "Переговорные",
        DESKS_TITLE: "Рабочие места",
        MEETING_ROOMS_TITLE: "Переговорные комнаты",
        COLLEAGUES_TITLE: "Коллеги",
        AMENITIES_NOT_SELECTED: "Удобства не выбраны",
        AMENITIES: "Удобства: ",
        CLEAR_BUTTON: "Сбросить",
        ACCEPT_BUTTON: "Применить",
        AVAILABLE_FOR_BOOKING: "Доступно для бронирования:",
        AMOUNT_OF_SEATS_TO: "до ",
        AMOUNT_OF_SEATS_PEOPLE: " человек",
        AMOUNT_OF_SEATS_NOT_FOUND: "не указано",
        AMOUNT_OF_SEATS_IS_NOT_FOUND: "Вместимость не указана",
        BOOKED_TITLE: "Забронировано",
        ALL_DAY_TITLE: "Весь день",
        BOOKINGS_COUNT_TITLE: "Бронирований: ",
        UNAVAILABLE_BOOKING_DESK_TITLE: "Данное рабочее место недоступно для бронирования",
        UNAVAILABLE_BOOKING_MEETING_ROOM_TITLE: "Данная переговорная комната недоступна для бронирования",
        NEW_BOOKING_BUTTON: "Новое бронирование",
        BOOK_NOW_BUTTON: "Забронировать",
        CANCEL_BOOKING_BUTTON: "Отменить бронирование",
        CANCEL_BOOKING_BTN: "Отменить бронь",
        BACK_BUTTON: "Назад",
        ALL_BOOKINGS: "Вcе бронирования",
        NO_BOOKINGS_YET: "На сегодня бронирований еще нет.",
        BOOKING_TITLE: "Бронирование: ",
        MEMBERS_TITLE: " участника",
        LOCAL_TIME_DESC: "Указано локальное время офиса",
        TIME_TITLE: "Время",
        IN_LOCAL_TIME_DESC: "В локальном времени офиса",
        MEMBERS_BLOCK_TITLE: "Участники",
        MEMBERS_BLOCK_SEARCH_PLACEHOLDER: "Начните печатать имя или email коллеги/гостя...",
        MEMBERS_BLOCK_ADD: "Добавить",
        MEMBERS_BLOCK_AS_GUEST: "как гостя",
        MEMBERS_BLOCK_GUEST_DESC: "Гость",
        MEMBERS_BLOCK_ORG_DESC: "Организатор",
        FREE_DESK_POINT_TYPE: "Свободное рабочее место",
        FREE_MEET_POINT_TYPE: "Свободная переговорная",
        BOOKED_DESK_POINT_TYPE: "Забронированное рабочее место",
        BOOKED_MEET_POINT_TYPE: "Забронированное переговорная",
        BUSY_DESK_POINT_TYPE: "Занятое рабочее место",
        BUSY_MEET_POINT_TYPE: "Занятая переговорная",
        UNAVAILABLE_DESK_POINT_TYPE: "Недоступное рабочее место",
        UNAVAILABLE_MEET_POINT_TYPE: "Недоступная переговорная"
    },
    BOOKING_CALENDAR: {
        TODAY_FILTER: "Сегодня",
        DESKS_TAB: "Рабочие места",
        MEETING_ROOMS: "Переговорные комнаты",
        EXPORT_BUTTON: "Экспорт",
        NEW_BOOKING_BUTTON: "Новое бронирование",
        DAY_FILTER_SELECT: "День",
        WEEK_FILTER_SELECT: "Неделя",
        MONTH_FILTER_SELECT: "Месяц",
        YES_FILTER_SELECT: "Да",
        NO_FILTER_SELECT: "Нет",
        AGREED_TOOLTIP_TEXT: "Согласованные бронирования не подлежат редактированию",
        SHOW_ALL_LINK_TEXT: "Показать всех...",
        ADD_DESK_RESERVE_TITLE: "Бронирование рабочего места",
        EDIT_DESK_RESERVE_TITLE: "Редактирование бронирования рабочего места",
        DESK_INVALID_FEEDBACK: "Поле рабочее место обязательно для заполнения",
        DESK_SELECT_TITLE: "Рабочее место:",
        DATES_INVALID_FEEDBACK: "Поля дат обязательны для заполнения",
        DATES_SELECT_TITLE: "Время и дата:",
        REPEAT_CHECKBOX_TEXT: "Повторять",
        EACH_TEXT: "Каждый",
        DAY_TEXT: "день",
        EVERY_WORK_DAY: "Каждый рабочий день",
        EVERY_TEXT: "Каждую",
        WEEK_BY_DAY: "неделю, по дням:",
        EMPLOYEE_INVALID_FEEDBACK: "Поле сотрудник обязательно для заполнения",
        EMPLOYEE_SELECT_TITLE: "Сотрудник:",
        AGREED_CHECKBOX_TEXT: "Отметить согласованным",
        AGREED_WARNING_TEXT: "Согласованные бронирования не подлежат редактированию.",
        CANCEL_BUTTON: "Отменить",
        CANCEL_BOOKING_BUTTON: "Отменить бронирование",
        SAVE_BUTTON: "Сохранить",
        ADD_BUTTON: "Добавить"
    },
    MANAGEMENT: {
        SPACE_MANAGEMENT_TITLE: "Управление пространством",
        OFFICES_TITLE: "Офисы",
        AMENITIES_TITLE: "Удобства",
        USER_MANAGEMENT_TITLE: "Управление пользователями",
        EMPLOYEES_TITLE: "Сотрудники",
        GROUPS_TITLE: "Группы",
        ROLES_TITLE: "Роли",
        INTEGRATIONS_TITLE: "Интеграции",
        OTHER_TITLE: "Другое",
        ORGANIZATION_TITLE: "Организация",
        PRODUCTION_CALENDAR_TITLE: "Производственный календарь",
        // OFFICES
        OFFICES_TEXT_DESC: "Вы можете добавить и настроить здания офисов для вашей организации в этом разделе.",
        ADD_OFFICE_BUTTON: "Добавить офис",
        NO_OFFICE_YET_TITLE: "Офисы ещё не добавлены",
        NO_OFFICE_YET_TITLE_DESC: "Добавьте офисы своей организации для продолжения работы:",
        EDIT_TOOLTIP_TEXT: "Редактировать",
        SETTINGS_FLOORS_TOOLTIP_TEXT: "Настроить этажи",
        CUSTOM_SPACE_PLAN_TOOLTIP_TEXT: "Настроить план пространства",
        RESOURCES_TOOLTIP_TEXT: "Ресурсы",
        DELETE_TOOLTIP_TEXT: "Удалить",
        DELETE_OFFICE_TITLE: "Удалить офис",
        DELETE_OFFICE_DESC: "Вы действительно хотите удалить офис?",
        CANCEL_BUTTON: "Отменить",
        DELETE_BUTTON: "Удалить",
        NAME_TABLE_TITLE: "Название",
        FLOOR_TABLE_TITLE: "Этаж",
        ADDRESS_TABLE_TITLE: "Адрес",
        STATUS_TABLE_TITLE: "Статус",
        ACTIONS_TABLE_TITLE: "Действия",
        ADD_OFFICE_TITLE: "Добавить офис",
        EDIT_OFFICE_TITLE: "Редактировать офис",
        NAME_OFFICE_INVALID_FEEDBACK: "Название офиса обязательно для заполнения",
        NAME_OFFICE_INPUT_TITLE: "Название офиса:",
        NAME_OFFICE_PLACEHOLDER: "Выберите название нового офиса...",
        COUNTRY_INVALID_FEEDBACK: "Поле страна обязательно для заполнения",
        COUNTRY_SELECT_TITLE: "Страна:",
        CITY_INVALID_FEEDBACK: "Поле город обязательно для заполнения",
        CITY_SELECT_TITLE: "Город:",
        ADDRESS_INVALID_FEEDBACK: "Поле адрес обязательно для заполнения",
        ADDRESS_INPUT_TITLE: "Адрес:",
        ADDRESS_PLACEHOLDER: "Например, 'пл. Революции, 23 стр.3'",
        TIMEZONE_INVALID_FEEDBACK: "Поле часовой пояс обязательно для заполнения",
        TIMEZONE_SELECT_TITLE: "Часовой пояс:",
        ADD_SETTING_FLOOR: "Добавить и настроить этажи",
        SAVE_BUTTON: "Сохранить",
        ADD_BUTTON: "Добавить",
        EDIT_BUTTON: "Редактировать",
        FLOORS_TITLE: "Этажи",
        FLOORS_TEXT_DESC: "Вы можете добавить этажи для здания офиса и расположить на них рабочие пространства.",
        ADD_FLOOR_BUTTON: "Добавить этаж",
        NO_FLOOR_YET_TITLE: "Этажи ещё не добавлены",
        NO_FLOOR_YET_TITLE_DESC: "Добавьте этажи данного офиса для продолжения работы:",
        FLOORS_TABLE_TITLE: "Этажи",
        ADD_FLOOR_TITLE: "Добавить этаж",
        EDIT_FLOOR_TITLE: "Редактировать этаж",
        FLOOR_INVALID_FEEDBACK: "Название этажа обязательно для заполнения",
        FLOOR_INPUT_TITLE: "Название этажа:",
        FLOOR_PLACEHOLDER: "Выберите название нового этажа, например 'Этаж 21'...",
        ADD_SETTING_BUTTON: "Добавить и настроить",
        DELETE_FLOOR_TITLE: "Удалить этаж",
        DELETE_FLOOR_DESC: "Вы действительно хотите удалить этаж?",
        NO_FLOOR_PLAN_YET_TITLE: "План для этажа ещё не загружен",
        NO_FLOOR_PLAN_YET_TITLE_DESC: "Перетащите или выберите SVG-файл для загрузки:",
        NO_FLOOR_PLAN_YET_BUTTON: "Выбрать файл...",
        RESET_BUTTON: "Сбросить",
        SET_UP_DESKS_BUTTON: "Настроить рабочие места",
        SET_UP_MEETINGS_ROOMS_BUTTON: "Настроить переговорные",
        CHANGE_FLOOR_PLAN_BUTTON: "Сменить план этажа",
        FLOOR_PLAN_WARNING_TEXT: "Вы не можете загружать файлы этого типа, только SVG",
        NOT_ASSIGNED_TITLE: "Не назначено",
        DESK_TITLE: "Рабочее место",
        MEETING_ROOM_TITLE: "Переговорная комната",
        FLOOR_PLAN_EDIT_TITLE: "Редактор плана этажа",
        CLOSE_FLOOR_PLAN_EDIT_BUTTON: "Закрыть редактор",
        EXIT_FLOOR_PLAN_EDIT_DESC: "Вы уверены, что хотите покинуть страницу? У Вас есть несохранённые изменения.",
        EXIT_BUTTON: "Выйти",
        SETTING_UP_FLOOR_PLAN_TITLE: "Настройка плана этажа",
        RESOURCE_NOT_ASSIGNED_TITLE: "Ресурс не назначен",
        DESKS_TITLE: "Рабочих мест",
        MEETINGS_ROOMS_TITLE: "Переговорных комнат",
        RESOURCE_TYPE_SELECT_TITLE: "Тип ресурса:",
        RESOURCE_TYPE_PLACEHOLDER: "Тип ресурса",
        DESK_SELECT_TITLE: "Рабочее место:",
        DESK_PLACEHOLDER: "Рабочее место",
        MEET_ROOM_SELECT_TITLE: "Переговорная комната:",
        MEET_ROOM_PLACEHOLDER: "Переговорная комната",
        AMENITIES_SELECT_TITLE: "Удобства:",
        AMENITIES_PLACEHOLDER: "Добавить удобства",
        FLOOR_OCCUPANCY_LIMIT_TITLE: "Лимит заполняемости этажа",
        FLOOR_OCCUPANCY_LIMIT_DESC: "Установите лимит одновременного использования рабочих мест на этаже.",
        LIMIT_BY_PC: "шт.",
        DESKS_AVAILABLE_TITLE : "рабочих мест доступны для бронирования",
        SAVE_LIMIT_BUTTON: "Сохранить лимит",
        WARNING_TEXT_1: "Добавьте на план рабочие места и переговорные комнаты.",
        WARNING_TEXT_2: "Вы можете настроить ресурс, кликнув по соответствующей точке.",
        WARNING_TEXT_3: "Не забудьте сохранить изменения после редактирования.",
        NO_PLAN_YET_TITLE: "План для данного этажа ещё не загружен",
        NO_PLAN_YET_TITLE_DESC: "Для настройки рабочего пространства загрузите файл плана для данного этажа:",
        DOWNLOAD_PLAN_BUTTON: "Загрузить план",
        DESK_POINT_TYPE: "Рабочее место",
        MEET_ROOM_POINT_TYPE: "Переговорная комната",
        RESOURCES_TITLE: "Ресурсы",
        RESOURCES_DESKS_TITLE: "Рабочие места",
        RESOURCES_MEET_ROOMS_TITLE: "Переговорные комнаты",
        RESOURCES_DESKS_TITLE_DESC: "Вы можете добавить рабочие места для расположения их на планах этажей.",
        RESOURCES_MEET_ROOMS_TITLE_DESC: "Вы можете добавить переговорные для расположения их на планах этажей.",
        ADD_DESK_BUTTON: "Добавить рабочее место",
        NO_DESKS_YET_TITLE: "Рабочие места ещё не добавлены",
        NO_DESKS_YET_DESC: "Добавьте новые рабочие места для расположения их на плане:",
        ADD_MEET_ROOM_BUTTON: "Добавить переговорную",
        NO_MEET_ROOMS_YET_TITLE: "Переговорные комнаты ещё не добавлены",
        NO_MEET_ROOMS_YET_DESC: "Добавьте новые переговорные комнаты для расположения их на плане:",
        AV_STATUS_TYPE: "Доступен",
        UN_STATUS_TYPE: "Недоступен",
        ADD_DESK_TITLE: "Добавить рабочее место",
        EDIT_DESK_TITLE: "Редактировать рабочее место",
        DELETE_DESK_TITLE: "Удалить рабочее место",
        DELETE_DESK_DESC: "Вы действительно хотите удалить рабочее место?",
        ADD_MEET_ROOM_TITLE: "Добавить переговорную комнату",
        EDIT_MEET_ROOM_TITLE: "Редактировать переговорную комнату",
        DELETE_MEET_ROOM_TITLE: "Удалить переговорную комнату",
        DELETE_MEET_ROOM_DESC: "Вы действительно хотите удалить переговорную комнату?",
        NAME_DESK_INVALID_FEEDBACK: "Название рабочего места обязательно для заполнения",
        NAME_DESK_SELECT_TITLE: "Название рабочего места:",
        NAME_DESK_PLACEHOLDER: "Введите название нового рабочего места...",
        DESK_STATUS_INVALID_FEEDBACK: "Поле статус обязательно для заполнения",
        DESK_STATUS_SELECT_TITLE: "Статус:",
        RESOURCE_ACCESS_TITLE: "Доступ к ресурсу:",
        RESOURCE_ACCESS_PLACEHOLDER: "Начните печатать название группы...",
        NO_RESOURCE_YET_TITLE: "На данный момент ресурс доступен для всех. Вы можете ограничить доступ к ресурсу посредством добавления необходимых групп сотрудников.",
        RESOURCE_ACCESS_LIST_TITLE: "Ресурс доступен для групп:",
        NAME_MEET_ROOM_INVALID_FEEDBACK: "Название переговорной комнаты обязательно для заполнения",
        NAME_MEET_ROOM_SELECT_TITLE: "Название переговорной комнаты:",
        NAME_MEET_ROOM_PLACEHOLDER: "Введите название новой переговорной комнаты...",
        MEET_ROOM_STATUS_INVALID_FEEDBACK: "Поле статус обязательно для заполнения",
        MEET_ROOM_STATUS_SELECT_TITLE: "Статус:",
        SQUARE_INPUT_TITLE: "Площадь:",
        SQUARE_PLACEHOLDER: "Введите значение площади...",
        CAPACITY_INPUT_TITLE: "Вместимость:",
        CAPACITY_INPUT_PLACEHOLDER: "Введите значение вместимости...",
        PEOPLE_TITLE: "чел.",
        // AMENITIES:
        AMENITIES: "Удобства",
        AMENITIES_DESC: "Вы можете добавить удобства и прикрепить их к рабочим местам и переговорным комнатам.",
        ADD_AMENITIES: "Добавить удобство",
        AMENITIES_NOT_YET_TITLE: "Удобства ещё не добавлены",
        AMENITIES_NOT_YET_DESC: "Добавьте удобства для прикрепления их к рабочим местам и переговорным комнатам:",
        DESKS_TABLE_TITLE: "Рабочие места",
        MEET_ROOMS_TABLE_TITLE: "Переговорные комнаты",
        ADD_AMENITIES_TITLE: "Добавить удобство",
        EDIT_AMENITIES_TITLE: "Редактировать удобство",
        NAME_AMENITY_INVALID_FEEDBACK: "Название удобства обязательно для заполнения",
        NAME_AMENITY_INPUT_TITLE: "Название:",
        NAME_AMENITY_INPUT_PLACEHOLDER: "Выберите название нового удобства...",
        DESK_AVAILABLE_CHECKBOX_TITLE: "Доступно для рабочих мест:",
        MEET_ROOM_AVAILABLE_CHECKBOX_TITLE: "Доступно для переговорных комнат:",
        DELETE_AMENITIES_TITLE: "Удалить удобство",
        DELETE_AMENITIES_DESC: "Вы точно хотите удалить удобство? При удалении оно исчезнет из фильтров при поиске.",
        // EMPLOYEES
        EMPLOYEES: "Сотрудники",
        EMPLOYEES_DESC: "Сотрудников в вашей организации:",
        EMPLOYEE_ACTIVE_STATUS: "Активен",
        EMPLOYEE_WAIT_STATUS: "Ожидает",
        EMPLOYEE_BLOCKED_STATUS: "Блокирован",
        LAST_NAME_TABLE_TITLE: "Имя",
        ROLES_TABLE_TITLE: "Роли",
        GROUPS_TABLE_TITLE: "Группы",
        EMPLOYEE_PROFILE: "Профиль сотрудника",
        PERSONAL_DATA: "Персональные данные",
        LAST_NAME_TITLE: "Фамилия:",
        FIRST_NAME_TITLE: "Имя:",
        PATRONYMIC_TITLE: "Отчество:",
        JOB_TITLE: "Должность:",
        PHONE_NUMBER_TITLE: "Телефон:",
        MASTER_SYSTEM: "Мастер система:",
        EMPLOYEE_GROUPS: "Группы пользователя:",
        EMPLOYEE_ROLES: "Роли пользователя:",
        ACS_TITLE: "СКУД",
        ACS_CARD_NUMBER_TITLE: "Номер СКУД-карты:",
        EMPLOYEE_ACS_CARD_TITLE: "СКУД-карта сотрудника",
        ACS_CARD_INPUT_INVALID_FEEDBACK: "Номер карты обязательно для заполнения",
        ACS_CARD_INPUT_TITLE: "Номер карты:",
        ACS_CARD_INPUT_PLACEHOLDER: "Введите номер СКУД-карты...",
        ACS_CARD_INPUT_DESC: "Вы можете добавить номер СКУД-карты через считыватель. Для этого нужно кликнуть по полю ввода и поднести карту к считывателю.",
        // GROUPS
        GROUPS: "Группы",
        GROUPS_DESC: "Групп в вашей организации:",
        MEMBER_COUNT_TABLE_TITLE: "Количество участников",
        IN_REPORTS_TABLE_TITLE: "Доступна в отчетах",
        IN_REPORTS_TABLE_DESC: "Группа будет отображаться в отчётах \"Посещаемость сотрудников\" и \"Посещаемость групп\", а также в  календаре бронирования",
        GROUPS_NOT_YET_TITLE: "В вашей организации ещё нет групп",
        GROUPS_NOT_YET_DESC: "Добавление групп пользователей возможно посредствам синхронизации с внешней системой учёта пользователей.",
        GROUP_SETTING_TOOLTIP_TEXT: "Настройки",
        GROUP_EDIT_TITLE: "Редактирование группы",
        GROUP_SETTING_NAME: "Название группы:",
        EMPTY_GROUP_MEMBERS_TABLE: "Таблица участников группы пуста.",
        MEMBERS_IN_GROUP_TITLE: "Участники группы",
        MEMBERS_IN_GROUP_TOTAL: "Участников в группе:",
        // ROLES
        ROLES: "Роли",
        ROLES_DESC: "Роли позволяют определить разрешения и уровни доступа, необходимые пользователям для работы с Deskki.",
        DEFAULT_LABEL: "По умолчанию",
        ROLE_TABLE_TITLE: "Роль",
        TYPE_TABLE_TITLE: "Тип",
        // ORGANIZATION
        ORGANIZATION: "Организация",
        ORGANIZATION_DESC: "Вы можете настроить данные организации, которые будут доступны пользователям.",
        ORGANIZATION_INPUT_INVALID_FEEDBACK: "Название организации обязательно для заполнения",
        ORGANIZATION_INPUT_TITLE: "Название:",
        ORGANIZATION_INPUT_PLACEHOLDER: "Введите название организации...",
        LOGO_INPUT_TITLE: "Логотип:",
        LOGO_INPUT_DESC: "Изображение формата PNG, JPG, JPEG, SVG размером не более 200 Кб.",
        LOGO_INPUT_WARNING: "Файл больше 200 Кб",
        // PRODUCTION CALENDAR
        PRODUCTION_CALENDAR_DESC: "Вы можете настроить рабочие и праздничные дни для вашей организации в этом разделе.",
        WORKING_TIME_PARAMS: "Параметры рабочего времени",
        OFF_DAYS_OF_WEEK: "Выходные дни недели",
        WORKING_HOURS_TITLE: "Рабочие часы",
        WEEKENDS_AND_HOLIDAYS: "Выходные и нерабочие праздничные дни в году",
        WEEKENDS_AND_HOLIDAYS_TOOLTIP: "В этом поле вы можете указать государственные и корпоративные праздники, нерабочие дни по указу Президента",
        WEEKENDS_AND_HOLIDAYS_PLACEHOLDER: "Введите дату в формате 01.01.2022",
        WEEKENDS_AND_HOLIDAYS_ADD_BTN: "ДОБАВИТЬ",
        APPLY_BUTTON: "Применить",
        // SIGUR
        AUTHORIZATION_TITLE: "Авторизация",
        AUTHORIZATION_DESC: "Введите авторизационные данные администратора СКУД Sigur для включения синхронизации с Deskki.",
        TOKEN_INPUT_INVALID_FEEDBACK: "Токен обязателен для заполнения",
        TOKEN_INPUT_TITLE: "Токен:",
        TOKEN_INPUT_PLACEHOLDER: "Введите токен...",
        ACCESS_POINTS_TITLE: "Точки доступа",
        ACCESS_POINTS_DESC: "Добавьте в Deskki точки доступа из Sigur для обеспечения разграничения доступа для групп сотрудников.",
        ADD_ACCESS_POINTS_BUTTON: "Добавить точку доступа",
        SETTING_ACCESS_TOOLTIP_TEXT: "Настроить доступ",
        ACCESS_POINTS_NOT_YET: "На данный момент ни одной точки доступа не было добавлено.",
        OFFICE_TABLE_TITLE: "Офис",
        ADD_ACCESS_POINT_TITLE: "Добавить точку доступа",
        EDIT_ACCESS_POINT_TITLE: "Редактировать точку доступа",
        OFFICE_SELECT_INVALID_FEEDBACK: "Поле офис обязательно для заполнения",
        OFFICE_SELECT_TITLE: "Офис:",
        FLOOR_SELECT_INVALID_FEEDBACK: "Поле этаж обязательно для заполнения",
        FLOOR_SELECT_TITLE: "Этаж:",
        NAME_ACCESS_POINT_INPUT_INVALID_FEEDBACK: "Название точки доступа обязательно для заполнения",
        NAME_ACCESS_POINT_INPUT_TITLE: "Название точки доступа:",
        NAME_ACCESS_POINT_INPUT_PLACEHOLDER: "Выберите название точки доступа...",
        ID_ACCESS_POINT_INPUT_INVALID_FEEDBACK: "ID точки доступа в Sigur обязательно для заполнения",
        ID_ACCESS_POINT_INPUT_TITLE: "ID точки доступа в Sigur:",
        ID_ACCESS_POINT_INPUT_PLACEHOLDER: "Выберите ID точки доступа...",
        DELETE_ACCESS_POINT_TITLE: "Удалить точку доступа",
        DELETE_ACCESS_POINT_DESC: "Вы действительно хотите удалить точку доступа?",
        EMPLOYEE_GROUPS_TITLE: "Группы сотрудников",
        EMPLOYEE_GROUPS_DESC: "Добавьте группы сотрудников для данной точки для обеспечения доступа к ней.",
        ADD_GROUP_BUTTON: "Добавить группы",
        EMPLOYEE_GROUPS_NOT_YET: "На данный момент ни одной группы для данной точки доступа не было добавлено.",
        GROUP_TABLE_TITLE: "Группа",
        ADD_GROUPS_TITLE: "Добавить группы",
        GROUPS_SELECT_INVALID_FEEDBACK: "Поле группы обязательно для заполнения",
        GROUPS_SELECT_TITLE: "Выберите группы:",
        GROUPS_SELECT_PLACEHOLDER: "Начните печатать название группы...",
        GROUPS_SELECT_SEARCH: "Начните печатать для поиска...",
        DELETE_GROUPS_TITLE: "Удалить группу",
        DELETE_GROUPS_DESC: "Вы действительно хотите удалить группу?",
        SETTINGS_TITLE: "Настройки",
        PERIOD_INPUT_INVALID_FEEDBACK: "Период обязателен для заполнения",
        PERIOD_INPUT_TITLE: "Период проверки работоспособности системы, секунд:",
        PERIOD_INPUT_PLACEHOLDER: "Введите период...",
        LOGS_TITLE: "Ошибки",
        LOGS_DESC: "Здесь отображаются ошибки синхронизации СКУД Sigur.  Для уточнения периода воспользуйтесь фильтром.",
        PERIOD_TITLE: "Период:",
        DATE_TIME_TABLE_TITLE: "Дата и время события",
        DESC_TABLE_TITLE: "Описание",
        // AZURE
        AZURE_AUTHORIZATION_DESC: "Для включения синхронизации пользователей, групп и бронирований кликните по кнопке «Включить синхронизацию» и пройдите процесс авторизации под системным пользователем. Данные системного пользователя можно узнать в интерфейсе Microsoft Azure.",
        SYNC_ENABLED_TITLE: "Синхронизация включена",
        SYNC_DISABLED_TITLE: "Синхронизация отключена",
        DISABLED_SYNC_BUTTON: "Отключить синхронизацию",
        ENABLED_SYNC_BUTTON: "Включить синхронизацию",
        MAPPING_MEET_ROOMS_TITLE: "Сопоставление переговорных",
        MAPPING_MEET_ROOMS_DESC: "Для сопоставления бронирований переговорных комнат между Microsoft Azure и Deskki необходимо сопоставить переговорные комнаты с соответствующими учётными записями, которые отвечают за бронирование в Azure.",
        MAPPING_MEET_ROOMS_NOT_YET: "На данный момент ни одной переговорной не добавлено.",
        MEET_ROOM_TABLE_TITLE: "Переговорная комната",
        MIC_AZ_ACCOUNT_TABLE_TITLE: "Учётная запись Microsoft Azure",
        AZURE_LOGS_DESC: "Здесь отображаются ошибки синхронизации с Microsoft Azure.  Для уточнения периода воспользуйтесь фильтром.",
    },
    ANALYTICS: {
        UTILIZATION_TITLE: "Утилизация",
        DESKS_UTILIZATION_TITLE: "Утилизация рабочих мест",
        MEETING_ROOMS_UTILIZATION_TITLE: "Утилизация переговорных комнат",
        OTHER_TITLE: "Другое",
        OFFICE_ATTENDANCE_TITLE: "Посещаемость офиса",
        EMPLOYEE_ATTENDANCE_TITLE: "Посещаемость сотрудников",
        GROUP_ATTENDANCE_TITLE: "Посещаемость групп",
        WARNING_TEXT: "Выберите этаж для отображения графика.",
        PERIOD_TITLE: "Период:",
        ACCESS_POINTS_TITLE: "Точки доступа:",
        OFFICES_TITLE: "Офисы:",
        FLO0RS_TITLE: "Этажи:",
        DESKS_TITLE: "Рабочие места:",
        MEETING_ROOMS_TITLE: "Переговорные:",
        WORKING_HOURS_TITLE: "Рабочие часы",
        SETTINGS_BUTTON: "Настроить",
        LIMIT_SET_TITLE: "Указывать лимит:",
        LIMIT_SET_TOOLTIP_TEXT: "Если включено, то утилизация будет расчитана с учётом лимита заполняемости этажа",
        MANUAL_PERIOD_FILTER_SELECT: "Ручной выбор периода",
        TIME_FROM: "От:",
        TIME_TO: "До:",
        EXPORT_BUTTON: "Экспорт",
        CANCEL_BUTTON: "Отменить",
        RESET_BUTTON: "Сбросить",
        APPLY_BUTTON: "Применить",
        NO_WORK_DAYS: "Нерабочий день",
        TOTAL_VISITORS_POINT: "Всего посетителей",
        PLAN_TABLE_TITLE: "План",
        FACT_TABLE_TITLE: "Факт",
        GROUP_TABLE_TITLE: "Группа",
        EMPLOYEE_TABLE_TITLE: "Сотрудник",
        ASSIGMENT_TABLE_TITLE: "Распределение",
        EMPLOYEES_NUMBER_TABLE_TITLE: "Количество сотрудников",
        QUOTA_TABLE_TITLE: "Доля"
    },
    SUCCESS_MESSAGES: {
        OFFICE_ADDED: "Офис успешно добавлен!",
        OFFICE_EDITED: "Данные офиса успешно изменены!",
        OFFICE_DELETED: "Офис успешно удалён!",
        FLOOR_ADDED: "Новый этаж успешно добавлен!",
        FLOOR_EDITED: "Данные этажа успешно изменены!",
        FLOOR_DELETED: "Этаж успешно удалён!",
        FLOOR_LIMIT: "Лимит для этажа успешно установлен!",
        SAVE_TEXT: "Сохранено успешно!",
        DELETE_TEXT: "Успешно удалёно!",
        FLOOR_PLAN_SAVE: "План этажа успешно сохранён!",
        PACS_CARD_SAVE: "СКУД-карта успешно назначена!",
        GROUP_EDITED: "Данные группы успешно изменены!",
        BOOKING_CREATED: "Бронирование успешно создано!",
        BOOKING_EDITED: "Бронирование успешно изменено!",
        BOOKING_CANCELED: "Бронирование успешно отменено!",
        AMENITY_ADDED: "Удобство успешно добавлен!",
        AMENITY_EDITED: "Удобство успешно изменено!",
        AMENITY_DELETED: "Удобство успешно удалено!",
        DESK_BOOKED: "Рабочее место успешно забронировано!",
        MEET_BOOKED: "Переговорная комната успешно забронирована!",
        MEMBER_ADDED: "Участник успешно добавлен!",
        MEMBER_DELETED: "Участник успешно удалён!",
        ORG_SAVED: "Данные организации успешно сохранены!",
        DATA_SAVED: "Данные успешно сохранены!",
        ACCESS_POINT_ADDED: "Точка доступа успешно добавлена!",
        ACCESS_POINT_EDITED: "Точка доступа успешно изменена!",
        ACCESS_POINT_REMOVED: "Точка доступа успешно удалена!",
        GROUP_ADDED: "Группа успешно добавлена!",
        GROUP_REMOVED: "Группа успешно удалена!",
        DESK_ADDED: "Рабочее место успешно добавлено!",
        MEET_ADDED: "Переговорная комната успешно добавлена!",
        CHANGED_ADDED: "Изменения успешно применены!",
        RESOURCE_DELETED: "Ресурс успешно удалён!",
    },
    ERROR_MESSAGES: {
        TEXT: "Произошла ошибка. Пожалуйста, попробуйте еще раз.",
    }
};
