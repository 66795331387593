import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import $ from "jquery";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const REFRESH_TOKEN = "refreshToken";
export const UPDATE_PASSWORD = "updateUser";
export const MICROSOFT_LOGIN_LINK = "microsoft_login_link";
export const MICROSOFT_LOGIN = "microsoft_login";
export const MICROSOFT_LOGOUT = "microsoft_logout";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_PASSWORD = "setPassword";
export const SET_SUCCESS = "setSuccess";
export const SET_ERROR = "setError";

const state = {
  success: null,
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("auth/login", credentials)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_AUTH, data);
          sendAmplitudeEvent('sign-in completed', { 'type': 'classic' });
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          resolve(data);
          context.commit(SET_AUTH, data);
          sendAmplitudeEvent('sign-up completed');
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data);
        });
    });
  },
  [REFRESH_TOKEN](context) {
    if (JwtService.getRefreshToken()) {
      return fetch(ApiService.refreshToken(), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          refresh_token: JwtService.getRefreshToken(),
        }),
      }).then((res) => res.json())
          .then(data  => {
            context.commit(SET_AUTH, data)
            // window.location.reload();
          });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.setLanguageHeader();
      // ApiService.get("verify")
      //   .then(({ data }) => {
      //     context.commit(SET_AUTH, data);
      //   })
      //   .catch(({ response }) => {
      //     context.commit(SET_ERROR, response.data);
      //   });
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;
    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [MICROSOFT_LOGIN_LINK](context) {
    return new Promise(resolve => {
      //  ApiService.query("/auth/azure", { params: { url: ApiService.enterPoint() }})
      ApiService.get("/auth/azure")
          .then(({ data }) => {
            resolve(data);
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data);
            resolve(response)
          });
    });
  },
  [MICROSOFT_LOGIN](context, payload) {
    return new Promise(resolve => {
      ApiService.query("/auth/azure/check", { params: payload})
          .then(({ data }) => {
            resolve(data);
            context.commit(SET_AUTH, data);
            sendAmplitudeEvent('sign-in completed', { 'type': 'azure' });
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data);
            resolve(response);
          });
    });
  },
  [MICROSOFT_LOGOUT](context, payload) {
    return new Promise(resolve => {
      ApiService.post("auth/azure/logout", payload)
          .then(({ data }) => {
            resolve(data);
            context.commit(PURGE_AUTH);
            sendAmplitudeEvent('sign-out completed', { 'type': 'azure' });
          })
          .catch(({ response }) => {
            context.commit(SET_ERROR, response.data);
          });
    });
  }
};

const mutations = {
  [SET_SUCCESS](state, success) {
    state.success = success;
    $.notify({
      // title: 'УСПЕШНО',
      message: state.success
    },{
      type: 'success',
      delay: 5000,
      placement: {
        from: "bottom",
        align: "left"
      },
      allow_dismiss: false,
      z_index: 1031,
    });
  },
  [SET_ERROR](state, data) {
    if (data.violations !== undefined) {
      state.errors = data.violations[0].title;
    } else {
      state.errors = data.error ? data.error.message : data.message;
    }
    $.notify({
      // title: 'ОШИБКА',
      message: state.errors === undefined ? 'Ошибка соединения' : state.errors
    },{
      type: 'danger',
      delay: 7000,
      placement: {
        from: "bottom",
        align: "left"
      },
      allow_dismiss: false,
      z_index: 1031,
    });
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
    if (window.localStorage.getItem("remember_me") === 'true') {
      JwtService.saveRefreshToken(state.user.refresh_token)
    }
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
    JwtService.destroyRefreshToken();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
