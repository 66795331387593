import ApiService from "@/core/services/api.service";
import { SET_ERROR, SET_SUCCESS } from "@/core/services/store/auth.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";
import i18n from "../../../plugins/vue-i18n";

// action types
export const GET_ORGANIZATION = "getOrganization";
export const POST_ORGANIZATION = "postOrganization";

// mutation types
export const SET_ORGANIZATION = "setOrganization";

const state = {
    organization: {
        name: null,
        logo: null
    }
};

const getters = {
    organization(state) {
        return state.organization;
    }
};

const actions = {
    [GET_ORGANIZATION](context) {
        ApiService.get("management/settings/organization/show")
            .then(({ data }) => {
                context.commit(SET_ORGANIZATION, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [POST_ORGANIZATION](context, payload) {
        return new Promise(resolve => {
            ApiService.post("management/settings/organization/edit", payload.form)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.ORG_SAVED") );
                    sendAmplitudeEvent('management-organization edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-organization edit', { 'result': 'error' });
                });
        });
    }
};

const mutations = {
    [SET_ORGANIZATION](state, data) {
        state.organization = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
