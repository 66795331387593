import ApiService from "@/core/services/api.service";
import moment from 'moment';
import { SET_ERROR, SET_SUCCESS } from "@/core/services/store/auth.module";
import {sendAmplitudeEvent} from "@/core/services/amplitude.service";
import store from "@/core/services/store";
import i18n from "../../plugins/vue-i18n";

// action types
export const GET_OFFICES_LIST = "getOfficesList";
export const GET_OFFICES_LIST_BY_ID = "getOfficesListById";
export const GET_OFFICE = "getOffice";
export const POST_NEW_OFFICE = "postNewOffice";
export const PUT_OFFICE = "putOffice";
export const DELETE_OFFICE = "deleteOffice";
export const GET_COUNTRY_CITY_LIST = "getCountryCityList";
export const GET_TIMEZONES_LIST = "getTimezonesList";
export const GET_FLOORS_LIST_BY_ID = "getFloorsListById";
export const GET_FLOOR = "getFloor";
export const POST_FLOOR = "postFloor";
export const PUT_FLOOR = "putFloor";
export const DELETE_FLOOR = "deleteFloor";
// export const GET_POINTS_TYPES = "GetPointsTypes";
export const SET_ACTIVE_POINT = "setActivePoint";
export const UNSET_ACTIVE_POINT = "unsetActivePoint";
export const GET_OCCUPANCY_LIMIT = "getOccupancyLimit";
export const PUT_OCCUPANCY_LIMIT = "putOccupancyLimit";
export const POST_NEW_FLOOR_POINT = "postNewFloorPoint";
export const PUT_FLOOR_POINT = "putFloorPoint";
export const DELETE_FLOOR_POINT = "deleteFloorPoint";
export const POST_FLOOR_IMG = "postFloorImg";
export const GET_FLOOR_IMG = "getFloorImg";
export const GET_EMPLOYEES_LIST = "getEmployeesList";
export const POST_EMPLOYEES_PHOTOS = "postEmployeesPhotos";
export const GET_EMPLOYEE = "getEmployee";
export const GET_EMPLOYEE_PHOTO = "getEmployeePhoto"
export const POST_EMPLOYEE_CARD = "postEmployeeCard";
export const GET_ROLES_LIST = "getRolesList";
export const GET_GROUPS_LIST = "getGroupsList";
export const GET_STATUSES_LIST ="getStatusesList";
export const GET_GROUP = "getGroup";
export const PUT_GROUP = "putGroup";
export const POST_NEW_GROUP = "postNewGroup";

// mutation types
export const SET_OFFICES_LIST = "setOfficesList";
export const SET_OFFICE = "setOffice";
export const SET_COUNTRY_CITY_LIST = "setCountryCityList";
export const SET_TIMEZONES_LIST = "setTimezonesList";
export const SET_FLOORS_LIST = "setFloorsList";
export const SET_FLOOR = "setFloor";
// export const SET_POINTS_TYPES = "setPointsTypes";
export const ENABLE_ACTIVE_POINT = "enableActivePoint";
export const DISABLE_ACTIVE_POINT = "disableActivePoint";
export const SET_OCCUPANCY_LIMIT = "setOccupancyLimit";
export const SET_FLOOR_IMG = "setFloorImg";
export const SET_EMPLOYEES_LIST = "setEmployeesList";
export const SET_EMPLOYEE = "setEmployee";
export const SET_ROLES_LIST = "setRolesList";
export const SET_GROUPS_LIST = "setGroupsList";
export const SET_STATUSES_LIST = "setStatusesList";
export const SET_GROUP = "setGroup";

const state = {
    errors: null,
    offices: [],
    office: {
        floors: []
    },
    countryCity: [{
        cities: [],
        country: ''
    }],
    timezones: {
        items: [],
        pagination: {}
    },
    floors: [],
    floor: {
        office: {
            name: null
        },
        points: [],
        plan: {}
    },
    // pointsTypes: null,
    occupancyLimit: {
        status: 0,
        type: "absolute",
        value: null
    },
    floorImg: {
        image: null
    },
    employees: {
        items: [],
        pagination: {}
    },
    employee: {
        name: {}
    },
    roles: null,
    groups: {
        items: [],
        pagination: {}
    },
    statuses: null,
    group: {
        pagination: {}
    }
};

const getters = {
    officesList(state) {
        return state.offices;
    },
    office(state) {
        return state.office;
    },
    countryCityList(state) {
        return state.countryCity;
    },
    timezonesList(state) {
        return state.timezones.items;
    },
    floors(state) {
        return state.floors;
    },
    floor(state) {
        return state.floor;
    },
    // pointsTypesList(state) {
    //     return state.pointsTypes;
    // },
    occupancyLimit(state) {
        return state.occupancyLimit;
    },
    floorImg(state) {
        return state.floorImg;
    },
    employeesList(state) {
        return state.employees.items;
    },
    employeesListPagination(state) {
        return state.employees.pagination;
    },
    employee(state) {
        return state.employee;
    },
    rolesList(state) {
        return state.roles;
    },
    groupsList(state) {
        return state.groups.items;
    },
    groupsListPagination(state) {
        return state.groups.pagination;
    },
    statusesList(state) {
        return state.statuses;
    },
    group(state) {
        return state.group;
    }
};

const actions = {
    [GET_OFFICES_LIST](context) {
        ApiService.get("management/offices/list")
            .then(({ data }) => {
                context.commit(SET_OFFICES_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_OFFICES_LIST_BY_ID](context, payload) {
        return new Promise(resolve => {
            ApiService.query("management/offices/list", {params: {...payload.params}})
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_OFFICES_LIST, data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [GET_OFFICE](context, payload) {
        return new Promise(resolve => {
            ApiService.get("management/office/" + payload)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_OFFICE, data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [POST_NEW_OFFICE](context, payload) {
        return new Promise(resolve => {
            ApiService.post("management/office/add", payload)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.OFFICE_ADDED") );
                    sendAmplitudeEvent('management-office add', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office add', { 'result': 'error' });
                });
        });
    },
    [PUT_OFFICE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.put(`management/office/${payload.id}/edit`, payload.form)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.OFFICE_EDITED") );
                    sendAmplitudeEvent('management-office edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    reject(response);
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office edit', { 'result': 'error' });
                });
        })
    },
    [DELETE_OFFICE](context, payload) {
        return new Promise(resolve => {
            ApiService.delete(`/management/office/${payload}/remove`)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.OFFICE_DELETED") );
                    sendAmplitudeEvent('management-office delete', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office delete', { 'result': 'error' });
                });
        });
    },
    [GET_COUNTRY_CITY_LIST](context, payload) {
        ApiService.query("management/country-and-city/list", { params: {...payload.params} })
            .then(({ data }) => {
                context.commit(SET_COUNTRY_CITY_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_TIMEZONES_LIST](context) {
        ApiService.get("management/timezones/list")
            .then(({ data }) => {
                context.commit(SET_TIMEZONES_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_FLOORS_LIST_BY_ID](context, payload) {
        return new Promise(resolve => {
            ApiService.get("management/office/" + payload)
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_FLOORS_LIST, data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [GET_FLOOR](context, payload) {
        return new Promise(resolve => {
            ApiService.get("management/offices/floor/" + payload)
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_FLOOR, data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [POST_FLOOR](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/office/${payload.id}/add/floor`, payload.form)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.FLOOR_ADDED") );
                    sendAmplitudeEvent('management-office-floor add', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office-floor add', { 'result': 'error' });
                });
        });
    },
    [PUT_FLOOR](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/office/${payload.id}/edit/floor`, payload.form)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.FLOOR_EDITED") );
                    sendAmplitudeEvent('management-office-floor edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office-floor edit', { 'result': 'error' });
                });
        });
    },
    [DELETE_FLOOR](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/office/${payload.id}/remove/floor`, payload.form)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.FLOOR_DELETED") );
                    sendAmplitudeEvent('management-office-floor delete', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office-floor delete', { 'result': 'error' });
                });
        });
    },
    // POINTS
    // [GET_POINTS_TYPES](context) {
    //     ApiService.get("management/floor-planner/point/types/list")
    //         .then(({ data }) => {
    //             context.commit(SET_POINTS_TYPES, data);
    //         })
    //         .catch(({ response }) => {
    //             context.commit(SET_ERROR, response.data);
    //         })
    // },
    [SET_ACTIVE_POINT]({ commit, state }, { id }) {
        if (state.floor.points) {
            for (let i = 0, l = state.floor.points.length; i < l; i++) {
                if (i === id) {
                    commit(ENABLE_ACTIVE_POINT, i);
                    continue;
                }
                commit(DISABLE_ACTIVE_POINT, i);
            }
        }
    },
    [UNSET_ACTIVE_POINT]({ commit }, { index }) {
        commit(DISABLE_ACTIVE_POINT, index);
    },
    [GET_OCCUPANCY_LIMIT](context, payload) {
        ApiService.get(`management/offices/floor/${payload}/occupancy-limit`)
            .then(({ data }) => {
                context.commit(SET_OCCUPANCY_LIMIT, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [PUT_OCCUPANCY_LIMIT](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/offices/floor/${payload.id}/occupancy-limit/edit`, payload.data)
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_OCCUPANCY_LIMIT, data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.FLOOR_LIMIT") );
                })
                .catch(({response}) => {
                    resolve(response.data);
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [POST_NEW_FLOOR_POINT](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`management/offices/floor/${payload.id}/point/add`, payload.point)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.SAVE_TEXT") );
                    sendAmplitudeEvent('management-floorplan-point add', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-floorplan-point add', { 'result': 'error' });
                });
        });
    },
    [PUT_FLOOR_POINT](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/offices/floor/${payload.id}/point/edit`, payload.point)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.SAVE_TEXT") );
                    sendAmplitudeEvent('management-floorplan save', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-floorplan save', { 'result': 'error' });
                });
        });
    },
    [DELETE_FLOOR_POINT](context, payload) {
        return new Promise(resolve => {
            ApiService.delete(`management/offices/floor/${payload.id}/point/remove`, payload.pointId)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.DELETE_TEXT") );
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    // END POINTS
    [POST_FLOOR_IMG](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`management/offices/floor/${payload.id}/plan/upload`, payload.img)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.FLOOR_PLAN_SAVE") );
                    sendAmplitudeEvent('management-office-floor-plan add', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office-floor-plan add', { 'result': 'error' });
                });
        });
    },
    [GET_FLOOR_IMG](context, payload) {
        ApiService.get(`management/offices/floor/${payload}/plan/show`)
            .then(({ data }) => {
                context.commit(SET_FLOOR_IMG, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_EMPLOYEES_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query("management/employees/list", {params: {...payload}})
                .then(({data}) => {
                    let itemsWithPhoto = [];
                    let count = 0;
                    let avatarPhone = { items: [] };  // , expTime: ""
                    if (localStorage.getItem("avatarPhoto")) {
                        let isPhotoChanged = false;
                        let avatars = JSON.parse(localStorage.getItem("avatarPhoto"));
                        // if (moment(avatars.expTime).isBefore(moment(new Date, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY HH:mm"))) localStorage.removeItem("avatarPhoto");
                        data.items.forEach(item => {
                            avatars.items.forEach(avatar => {
                                if (item.id === avatar.id) {
                                    if (item.photo_hash === avatar.photo_hash) {
                                        item.profile.photo = avatar.photo;
                                    } else {
                                        store.dispatch(POST_EMPLOYEES_PHOTOS, { employees: [ item.id ] })
                                            .then(res => {
                                                item.profile.photo = res[0].photo.value;
                                                isPhotoChanged = true;
                                            });
                                    }
                                }
                            });
                            count++;
                            if (count === data.items.length) {
                                resolve(data);
                                context.commit(SET_EMPLOYEES_LIST, data);
                                isPhotoChanged === true ? localStorage.removeItem("avatarPhoto") : null;
                            }
                        });
                    } else {
                        data.items.forEach(item => {
                            store.dispatch(GET_EMPLOYEE_PHOTO, item.id).then(res => {
                                if (res.photo) {
                                    avatarPhone.items.push({ id: item.id, photo: res.photo, photo_hash: item.photo_hash })
                                }
                                item.profile.photo = res.photo;
                                itemsWithPhoto.push(item);
                                count++
                                if (count === data.items.length) {
                                    // avatarPhone.expTime = moment(new Date(), "DD.MM.YYYY HH:mm").add('days', 1).format('DD.MM.YYYY HH:mm');
                                    data.items = itemsWithPhoto;
                                    resolve(data);
                                    context.commit(SET_EMPLOYEES_LIST, data);
                                    localStorage.setItem("avatarPhoto", JSON.stringify(avatarPhone));
                                }
                            });
                        })
                    }
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [POST_EMPLOYEES_PHOTOS](context, payload) {
        return new Promise(resolve => {
            ApiService.post("management/employees/photos", payload)
                .then(({ data }) => {
                    resolve(data);
                })
        });
    },
    [GET_EMPLOYEE](context, payload) {
        return new Promise(resolve => {
            ApiService.get("management/employee/" + payload + "/show")
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_EMPLOYEE, data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [GET_EMPLOYEE_PHOTO](context, payload) {
        return new Promise(resolve => {
            ApiService.get("management/employee/" + payload + "/photo")
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [POST_EMPLOYEE_CARD](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/employee/${payload.id}/pacs/card/edit`, payload.code)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.PACS_CARD_SAVE") );
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [GET_ROLES_LIST](context, payload) {
        ApiService.query("management/roles/list", { params: { name: payload } })
            .then(({ data }) => {
                context.commit(SET_ROLES_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            });
    },
    [GET_GROUPS_LIST](context, payload) {
        return new Promise(resolve => {
            ApiService.query("management/groups/list", { params: {...payload} })
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_GROUPS_LIST, data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [GET_STATUSES_LIST](context) {
        ApiService.query("management/employee/statuses")
            .then(({ data }) => {
                context.commit(SET_STATUSES_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            });
    },
    [GET_GROUP](context, payload) {
        return new Promise(resolve => {
            ApiService.query("management/group/" + payload.id, { params: {...payload.params} })
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_GROUP, data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [PUT_GROUP](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/group/${payload.id}/edit`, payload.form)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.GROUP_EDITED") );
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [POST_NEW_GROUP](context, payload) {
        return new Promise(resolve => {
            ApiService.post("management/group/add", payload)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    }
};

const mutations = {
    [SET_OFFICES_LIST](state, data) {
        state.offices = data;
        // state.errors = {};
    },
    [SET_OFFICE](state, data) {
        state.office = data;
    },
    [SET_COUNTRY_CITY_LIST](state, data) {
        state.countryCity = data;
    },
    [SET_TIMEZONES_LIST](state, data) {
        state.timezones = data;
    },
    [SET_FLOORS_LIST](state, data) {
        state.floors = data.floors;
    },
    [SET_FLOOR](state, data) {
        state.floor = data;
    },
    // [SET_POINTS_TYPES](state, data) {
    //     state.pointsTypes = data;
    // },
    [ENABLE_ACTIVE_POINT](state, index) {
        if (state.floor.points && state.floor.points[index]) state.floor.points[index].active = true;
    },
    [DISABLE_ACTIVE_POINT](state, index) {
        if (state.floor.points && state.floor.points[index]) state.floor.points[index].active = false;
    },
    [SET_OCCUPANCY_LIMIT](state, data) {
        state.occupancyLimit = data;
    },
    [SET_FLOOR_IMG](state, data) {
        state.floorImg = data;
    },
    [SET_EMPLOYEES_LIST](state, data) {
        state.employees = data;
    },
    [SET_EMPLOYEE](state, data) {
        state.employee = data;
    },
    [SET_ROLES_LIST](state, data) {
        state.roles = data;
    },
    [SET_GROUPS_LIST](state, data) {
        state.groups = data;
    },
    [SET_STATUSES_LIST](state, data) {
        state.statuses = data;
    },
    [SET_GROUP](state, data) {
        state.group = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
