import ApiService from "@/core/services/api.service";
import { SET_ERROR, REFRESH_TOKEN } from "@/core/services/store/auth.module";
import { setAmplitudeUserDevice, setAmplitudeUserId } from "@/core/services/amplitude.service";
// import JwtService from "@/core/services/jwt.service";

// action types
export const GET_PROFILE = "getProfile";

// mutation types
export const SET_ACCOUNT_INFO = "setAccountInfo";

const state = {
  user_account_info: {
    name: {}
  }
};

const getters = {
  currentUserAccountInfo(state) {
    return state.user_account_info;
  },
  isCan(state) {
    return state.user_account_info.permissions;
  }
};

const actions = {
  [GET_PROFILE](context) {
    ApiService.get("/auth/profile")
        .then(({ data }) => {
          context.commit(SET_ACCOUNT_INFO, data);
        })
        .catch(({ response }) => {
          if (response.status >= 401 && response.status <= 403) this.dispatch(REFRESH_TOKEN);
          context.commit(SET_ERROR, response.data);
        });
  },
};

const mutations = {
  [SET_ACCOUNT_INFO](state, user_account_info) {
    state.user_account_info = user_account_info;
    setAmplitudeUserDevice(window.navigator.userAgentData.platform);  // JwtService.getToken()
    setAmplitudeUserId(user_account_info.id);
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
