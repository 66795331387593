import Vue from "vue";
import Vuex from "vuex";

import amenities from "./management/amenities.module";
import organization from "./management/organization.module";
import productionCalendar from "./management/productionCalendar.module";
import integrations from "./management/integrations.module";
import resources from "./management/resources.module";
import auth from "./auth.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import management from "./management.module";
import attendance from "./analytics/attendance.module";
import utilization from "./analytics/utilization.module";
import profile from "./profile.module";
import myOffice from "./myoffice/index";
import calendar from "./bookingCalendar/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    amenities,
    organization,
    productionCalendar,
    integrations,
    resources,
    auth,
    htmlClass,
    config,
    breadcrumbs,
    management,
    attendance,
    utilization,
    profile,
    myOffice,
    calendar
  }
});
