import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: 'history',
  hash: false,
  routes: [
    {
      path: "/",
      redirect: "/my-office",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/my-office",
          name: "my-office",
          component: () => import("@/view/pages/myoffice/MyOffice.vue")
        },
        {
          path: "/booking-calendar",
          name: "booking-calendar",
          component: () => import("@/view/pages/bookingcalendar/BookingCalendar.vue"),
          meta: {
            permissions: [
              'calendar-booking-desk-view',
              'calendar-booking-desk-edit',
              'calendar-booking-desk-agree',
              'calendar-booking-desk-export'
            ]
          }
        },
        {
          path: "/colleagues",
          name: "colleagues",
          component: () => import("@/view/pages/colleagues/Colleagues.vue")
        },
        {
          path: "/management",
          name: "management",
          redirect: "/management/offices",
          component: () => import("@/view/pages/management/Management.vue"),
          children: [
            {
              path: "offices",
              name: "offices",
              component: () => import("@/view/pages/management/offices/Offices.vue"),
              meta: {
                permissions: ['management', 'common-office-view', 'management-office-edit', 'common-office-floor-view']
              }
            },
            {
              path: "offices/office/:id",
              name: "office",
              component: () => import("@/view/pages/management/offices/Office.vue"),
              meta: {
                permissions: [
                  'management',
                  'management-office-floor-edit',
                  'management-office-floor-plan-view',
                  'common-resources-view',
                  'management-office-floor-plan-editor-view'
                ]
              }
            },
            {
              path: "offices/floor/:id",
              name: "floor",
              component: () => import("@/view/pages/management/offices/Floor.vue"),
              meta: {
                permissions: ['management', 'management-office-floor-plan-edit']
              }
            },
            {
              path: "offices/floor-resources/:id",
              name: "floor-resources",
              component: () => import("@/view/pages/management/offices/FloorResources.vue"),
              meta: {
                permissions: ['management', 'management-resources-edit']
              }
            },
            {
              path: "offices/floor-plan-editor/:id",
              name: "floor-plan-editor",
              component: () => import("@/view/pages/management/offices/FloorPlanEditor.vue"),
              meta: {
                permissions: ['management', 'management-office-floor-plan-editor-edit']
              }
            },
            {
              path: "amenities",
              name: "amenities",
              component: () => import("@/view/pages/management/amenities/Amenities.vue"),
              meta: {
                permissions: ['management', 'common-amenities-view', 'management-amenities-edit']
              }
            },
            {
              path: "employees",
              name: "employees",
              component: () => import("@/view/pages/management/employees/Employees.vue"),
              meta: {
                permissions: ['management', 'common-employees-view', 'management-employees-edit']
              }
            },
            {
              path: "employees/employee/:id",
              name: "employee",
              component: () => import("@/view/pages/management/employees/Employee.vue"),
              meta: {
                permissions: ['management']
              }
            },
            {
              path: "groups",
              name: "groups",
              component: () => import("@/view/pages/management/groups/Groups.vue"),
              meta: {
                permissions: ['management', 'common-employees-groups-view', 'management-employees-groups-edit']
              }
            },
            {
              path: "groups/group/:id",
              name: "group",
              component: () => import("@/view/pages/management/groups/Group.vue"),
              meta: {
                permissions: ['management']
              }
            },
            {
              path: "roles",
              name: "roles",
              component: () => import("@/view/pages/management/roles/Roles.vue"),
              meta: {
                permissions: ['management', 'management-roles-view', 'management-roles-edit']
              }
            },
            {
              path: "sigur",
              name: "sigur",
              component: () => import("@/view/pages/management/integrations/Sigur.vue"),
              meta: {
                permissions: ['management', 'management-integration-pacs-sigur-view', 'management-integration-pacs-sigur-edit']
              }
            },
            {
              path: "sigur/access-point/:id",
              name: "access-point",
              component: () => import("@/view/pages/management/integrations/AccessPoint.vue"),
              meta: {
                permissions: ['management', 'management-integration-pacs-sigur-view', 'management-integration-pacs-sigur-edit']
              }
            },
            {
              path: "azure",
              name: "azure",
              component: () => import("@/view/pages/management/integrations/Azure.vue"),
              meta: {
                permissions: ['management', 'management-integration-azure-view', 'management-integration-azure-edit']
              }
            },
            {
              path: "organization",
              name: "organization",
              component: () => import("@/view/pages/management/organization/Organization.vue"),
              meta: {
                permissions: ['management', 'common-organization-view', 'management-organization-edit']
              }
            },
            {
              path: "production-calendar",
              name: "production-calendar",
              component: () => import("@/view/pages/management/productioncalendar/ProductionCalendar.vue"),
              meta: {
                permissions: ['management', 'management-settings-production-calendar-view', 'management-settings-production-calendar-edit']
              }
            },
          ]
        },
        {
          path: "/analytics",
          name: "analytics",
          redirect: "/analytics/workplace-utilization",
          component: () => import("@/view/pages/analytics/Analytics.vue"),
          children: [
            {
              path: "workplace-utilization",
              name: "workplace-utilization",
              component: () => import("@/view/pages/analytics/utilization/WorkplacesUtilization.vue"),
              meta: {
                permissions: ['analytics', 'analytics-utilization-view']
              }
            },
            {
              path: "meeting-room-utilization",
              name: "meeting-room-utilization",
              component: () => import("@/view/pages/analytics/utilization/MeetingRoomsUtilization.vue"),
              meta: {
                permissions: ['analytics', 'analytics-utilization-view']
              }
            },
            {
              path: "office-attendance",
              name: "office-attendance",
              component: () => import("@/view/pages/analytics/attendance/OfficeAttendance.vue"),
              meta: {
                permissions: ['analytics', 'analytics-attendance-view']
              }
            },
            {
              path: "employees-attendance",
              name: "employees-attendance",
              component: () => import("@/view/pages/analytics/attendance/EmployeesAttendance.vue"),
              meta: {
                permissions: ['analytics', 'analytics-attendance-view']
              }
            },
            {
              path: "groups-attendance",
              name: "groups-attendance",
              component: () => import("@/view/pages/analytics/attendance/GroupsAttendance.vue"),
              meta: {
                permissions: ['analytics', 'analytics-attendance-view']
              }
            },
          ]
        },
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/Auth"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/Auth")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/AzureLogout"),
      children: [
        {
          name: "azure-logout",
          path: "/azure-logout",
          component: () => import("@/view/pages/auth/AzureLogout")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error.vue")
    }
  ]
});
