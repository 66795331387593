import amplitude from 'amplitude-js';

// not connected yet
/*
    management-office-floor-plan edit
    management-office-floor-plan delete

    management-employee add
    management-employee change-status
    management-employee delete
    management-group add
    management-group edit
    management-group delete
    management-group add-users
    management-role add
    management-role edit
    management-role delete
    management-role add-users
    management-role add-group
*/

export const amplitudeApiKeys = {
    McDonDev: '3483941b99d7431dc26c49b9c2b503a7',
    McDonProd: '352873960523d0e447888529f212429e'
};

export const initAmplitude = (apiKey) => {
    amplitude.getInstance().init(apiKey);
};

export const setAmplitudeUserDevice = (installationToken) => {
    amplitude.getInstance().setDeviceId(installationToken);
};

export const setAmplitudeUserId = (userId) => {
    amplitude.getInstance().setUserId(userId);
};

// export const setAmplitudeUserProperties = (properties) => {
//     amplitude.getInstance().setUserProperties(properties);
// };

export const sendAmplitudeEvent = (eventType, eventProperties) => {
    amplitude.getInstance().logEvent(eventType, eventProperties)
};
