import ApiService from "@/core/services/api.service";
import { SET_ERROR, SET_SUCCESS } from "@/core/services/store/auth.module";
import { sendAmplitudeEvent } from "@/core/services/amplitude.service";
import i18n from "../../../plugins/vue-i18n";

// action types
export const GET_DESKS_LIST = "getDeskList";
export const POST_NEW_DESK = "postNewDesk";
export const PUT_DESK = "putDesk";
export const ACTIVATE_DESK = "activateDesk";
export const DEACTIVATE_DESK = "deactivateDesk";
export const DELETE_DESK = "deleteDesk";
export const GET_MEETING_ROOMS_LIST = "getMeetingRoomsList";
export const POST_NEW_MEETING_ROOM = "postNewMeetingRoom";
export const PUT_MEETING_ROOM = "putMeetingRoom";
export const ACTIVATE_MEETING_ROOM = "activateMeetingRoom";
export const DEACTIVATE_MEETING_ROOM = "deactivateMeetingRoom";
export const DELETE_MEETING_ROOM = "deleteMeetingRoom";
export const GET_RESOURCE_ASSIGNED_GROUP = "getResourceAssignedGroup";
export const ADD_ASSIGN_GROUP = "addAssignGroup";
export const REMOVE_ASSIGN_GROUP = "removeAssignGroup";

// mutation types
export const SET_DESKS_LIST = "setDeskList";
export const SET_MEETING_ROOMS_LIST = "setMeetingRoomsList";
export const SET_RESOURCE_ASSIGNED_GROUP = "setResourceAssignedGroup";

const state = {
    errors: null,
    desks: {
        items: [],
        pagination: {}
    },
    meetingRooms: {
        items: [],
        pagination: {}
    },
    addedResourceGroups: [],
};

const getters = {
    deskList(state) {
        return state.desks;
    },
    meetingRoomsList(state) {
        return state.meetingRooms;
    },
    addedResourceGroups(state) {
        return state.addedResourceGroups;
    }
};

const actions = {
    [GET_DESKS_LIST](context, payload) {
        if (payload.id) {
            const amenities = payload.amenities && payload.amenities.length > 0 ? payload.amenities : null;
            return new Promise(resolve => {
                ApiService.postWithQuery(`management/offices/floor/${payload.id}/desks/list`, { amenities }, {params: {...payload.params}})
                    .then(({data}) => {
                        resolve(data);
                        context.commit(SET_DESKS_LIST, data);
                    })
                    .catch(({response}) => {
                        context.commit(SET_ERROR, response.data);
                    })
            });
        }
    },
    [POST_NEW_DESK](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`management/offices/floor/${payload.id}/desk/add`, payload.body)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.DESK_ADDED") );
                    sendAmplitudeEvent('management-office-floor-resource add', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office-floor-resource add', { 'result': 'error' });
                });
        });
    },
    [PUT_DESK](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/offices/floor/desk/${payload.id}/edit`, payload.form)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.CHANGED_ADDED") );
                    sendAmplitudeEvent('management-office-floor-resource edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office-floor-resource edit', { 'result': 'error' });
                });
        });
    },
    [ACTIVATE_DESK](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/offices/floor/desk/${payload.id}/activate`)
                .then(({ data }) => {
                    resolve(data);
                    // context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.CHANGED_ADDED") );
                    sendAmplitudeEvent('management-office-floor-resource change-status', { 'result': 'success', 'status': 'enabled' });
                })
                .catch(() => {
                    context.commit(SET_ERROR, i18n.t("ERROR_MESSAGES.TEXT") );
                    sendAmplitudeEvent('management-office-floor-resource change-status', { 'result': 'error', 'status': 'enabled' });
                });
        });
    },
    [DEACTIVATE_DESK](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/offices/floor/desk/${payload.id}/deactivate`)
                .then(({ data }) => {
                    resolve(data);
                    // context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.CHANGED_ADDED") );
                    sendAmplitudeEvent('management-office-floor-resource change-status', { 'result': 'success', 'status': 'disabled' });
                })
                .catch(() => {
                    context.commit(SET_ERROR, i18n.t("ERROR_MESSAGES.TEXT") );
                    sendAmplitudeEvent('management-office-floor-resource change-status', { 'result': 'error', 'status': 'disabled' });
                });
        });
    },
    [DELETE_DESK](context, payload) {
        return new Promise(resolve => {
            ApiService.delete(`management/offices/floor/desk/${payload.id}/remove`)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.RESOURCE_DELETED") );
                    sendAmplitudeEvent('management-office-floor-resource delete', { 'result': 'success' });
                })
                .catch(() => {
                    context.commit(SET_ERROR, i18n.t("ERROR_MESSAGES.TEXT") );
                    sendAmplitudeEvent('management-office-floor-resource delete', { 'result': 'error' });
                });
        });
    },
    [GET_MEETING_ROOMS_LIST](context, payload) {
        if (payload.id) {
            const amenities = payload.amenities && payload.amenities.length > 0 ? payload.amenities : null;
            return new Promise(resolve => {
                ApiService.postWithQuery(`management/offices/floor/${payload.id}/meeting-rooms/list`, {amenities}, {params: {...payload.params}})
                    .then(({data}) => {
                        resolve(data);
                        context.commit(SET_MEETING_ROOMS_LIST, data);
                    })
                    .catch(({response}) => {
                        context.commit(SET_ERROR, response.data);
                    })
            });
        }
    },
    [POST_NEW_MEETING_ROOM](context, payload) {
        return new Promise(resolve => {
            ApiService.post(`management/offices/floor/${payload.id}/meeting-room/add`, payload.body)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.MEET_ADDED") );
                    sendAmplitudeEvent('management-office-floor-resource add', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office-floor-resource add', { 'result': 'error' });
                });
        });
    },
    [PUT_MEETING_ROOM](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/offices/floor/meeting-room/${payload.id}/edit`, payload.form)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.CHANGED_ADDED") );
                    sendAmplitudeEvent('management-office-floor-resource edit', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('management-office-floor-resource edit', { 'result': 'error' });
                });
        });
    },
    [ACTIVATE_MEETING_ROOM](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/offices/floor/meeting-room/${payload.id}/activate`)
                .then(({ data }) => {
                    resolve(data);
                    // context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.CHANGED_ADDED") );
                    sendAmplitudeEvent('management-office-floor-resource change-status', { 'result': 'success', 'status': 'enabled' });
                })
                .catch(() => {
                    context.commit(SET_ERROR, i18n.t("ERROR_MESSAGES.TEXT") );
                    sendAmplitudeEvent('management-office-floor-resource change-status', { 'result': 'error', 'status': 'enabled' });
                });
        });
    },
    [DEACTIVATE_MEETING_ROOM](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/offices/floor/meeting-room/${payload.id}/deactivate`)
                .then(({ data }) => {
                    resolve(data);
                    // context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.CHANGED_ADDED") );
                    sendAmplitudeEvent('management-office-floor-resource change-status', { 'result': 'success', 'status': 'disabled' });
                })
                .catch(() => {
                    context.commit(SET_ERROR, i18n.t("ERROR_MESSAGES.TEXT") );
                    sendAmplitudeEvent('management-office-floor-resource change-status', { 'result': 'error', 'status': 'disabled' });
                });
        });
    },
    [DELETE_MEETING_ROOM](context, payload) {
        return new Promise(resolve => {
            ApiService.delete(`management/offices/floor/meeting-room/${payload.id}/remove`)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.RESOURCE_DELETED") );
                    sendAmplitudeEvent('management-office-floor-resource delete', { 'result': 'success' });
                })
                .catch(() => {
                    context.commit(SET_ERROR, i18n.t("ERROR_MESSAGES.TEXT") );
                    sendAmplitudeEvent('management-office-floor-resource delete', { 'result': 'error' });
                });
        });
    },
    [GET_RESOURCE_ASSIGNED_GROUP](context, payload) {
        ApiService.query(`management/offices/floor/resource/${payload.id}/assigned-group`)
            .then(({ data }) => {
                context.commit(SET_RESOURCE_ASSIGNED_GROUP, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            });
    },
    [ADD_ASSIGN_GROUP](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/offices/floor/resource/${payload.id}/assign-group`, payload.body)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
    [REMOVE_ASSIGN_GROUP](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`management/offices/floor/resource/${payload.id}/revoke-group`, payload.body)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                });
        });
    },
};

const mutations = {
    [SET_DESKS_LIST](state, data) {
        state.desks = data;
        // state.errors = {};
    },
    [SET_MEETING_ROOMS_LIST](state, data) {
        state.meetingRooms = data;
    },
    [SET_RESOURCE_ASSIGNED_GROUP](state, data) {
        state.addedResourceGroups = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
