import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/auth.module";
import i18n from "../../../plugins/vue-i18n";

// action types
export const GET_RESOURCES_UTILIZATION_REPORT = "getResourcesUtilizationReport";

// mutation types
export const SET_RESOURCES_UTILIZATION_REPORT = "setResourcesUtilizationReport";

const state = {
    periodList: [
        {
            id: 1,
            name: i18n.t("SELECT.SEVEN_DAYS")
        },
        {
            id: 2,
            name: i18n.t("SELECT.FOURTEEN_DAYS")
        },
        {
            id: 3,
            name: i18n.t("SELECT.THIRTY_DAYS")
        },
        {
            id: 4,
            name: i18n.t("SELECT.THIS_MONTH")
        },
        {
            id: 5,
            name: i18n.t("SELECT.PREV_MONTH")
        },
        {
            id: 6,
            name: i18n.t("SELECT.MANUAL_SELECT")
        },
    ],
    resourcesUtilizationReport: {
        previousData: [],
        selectedData: []
    }
};

const getters = {
    periodList(state) {
        return state.periodList;
    },
    resourcesUtilizationSelectedData(state) {
        return state.resourcesUtilizationReport.selectedData;
    },
    resourcesUtilizationPreviousData(state) {
        return state.resourcesUtilizationReport.previousData;
    }
};

const actions = {
    [GET_RESOURCES_UTILIZATION_REPORT](context, payload) {
        return new Promise(resolve => {
            ApiService.post("management/resources/reports/utilization-reports", payload)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_RESOURCES_UTILIZATION_REPORT, data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    }
};

const mutations = {
    [SET_RESOURCES_UTILIZATION_REPORT](state, data) {
        state.resourcesUtilizationReport = data;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
