import moment from 'moment';
import ApiService from "@/core/services/api.service";
import {SET_ERROR, SET_SUCCESS} from "@/core/services/store/auth.module";
import {GET_OFFICE, GET_OFFICES_LIST_BY_ID} from "@/core/services/store/management.module";
import {sendAmplitudeEvent} from "@/core/services/amplitude.service";
import i18n from "../../../plugins/vue-i18n";

// action types
export const GET_MY_OFFICE = "getMyOffice";
export const GET_MY_OFFICE_AMENITIES_LIST = "getMyOfficeAmenitiesList";
export const GET_MY_OFFICE_AMENITIES_LIST_FILTERED = "getMyOfficeAmenitiesListFiltered";
export const GET_AVAILABLE_RESOURCES = "getAvailableResources";
export const GET_BOOKING_DESK_LIST = "getBookingDeskList";
export const POST_RESERVE_DESK = "postReserveDesk";
export const PUT_RESERVE_DESK_CANCEL = "putReserveDeskCancel";
export const GET_AVAILABLE_EMPLOYEES = "getAvailableEmployees";
export const GET_BOOKING_MEETING_ROOM_LIST = "getBookingMeetingRoomList";
export const POST_RESERVE_MEETING_ROOM = "postReserveMeetingRoom";
export const PUT_RESERVE_MEETING_ROOM_EDIT = "putReserveMeetingRoomEdit";
export const PUT_RESERVE_MEETING_ROOM_CANCEL = "putReserveMeetingRoomCancel";
export const ASSIGN_MEMBER_TO_MEETING_ROOM = "assignMemberToMeetingRoom";
export const REVOKE_MEMBER_FROM_MEETING_ROOM = "revokeMemberFromMeetingRoom";
export const SELECT_NEW_COUNTRY = "selectNewCountry";
export const SELECT_NEW_CITY = "selectNewCity";
export const SELECT_NEW_OFFICE = "selectNewOffice";
export const SELECT_NEW_FLOOR = "selectNewFloor";
export const SET_SELECTED_FLOOR = "setSelectedFloor";

// mutation types
export const SET_MY_OFFICE = "setMyOffice";
export const SET_MY_OFFICE_AMENITIES_LIST = "setMyOfficeAmenitiesList";
export const SET_MY_OFFICE_AMENITIES_LIST_FILTERED = "setMyOfficeAmenitiesListFiltered";
export const SET_AVAILABLE_RESOURCES = "setAvailableResources"
export const SET_BOOKING_DESK_LIST = "setBookingDeskList";
export const SET_AVAILABLE_EMPLOYEES = "setAvailableEmployees";
export const SET_BOOKING_MEETING_ROOM_LIST = "setBookingMeetingRoomList";
export const SET_NEW_COUNTRY = "setNewCountry";
export const SET_NEW_CITY = "setNewCity";
export const SET_NEW_OFFICE = "setNewOffice";
export const SET_NEW_FLOOR = "setNewFloor";

const state = {
    myOffice: {
        office: {
            name: null
        },
        plan: {},
        points: []
    },
    myOfficePointFilter: [],
    myOfficeAmenitiesList: [],
    availableResources: [],
    allResources: null,
    bookingDeskList: null,
    availableEmployees: {
        items: [],
        pagination: {}
    },
    bookingMeetingRoomList: null,
    citiesList: null,
    floorsList: null,
    filter_country: null,
    filter_cities: null,
    filter_offices: null,
    filter_floors: null
};

const getters = {
    myOffice(state) {
        if (state.myOffice && state.myOffice.points) {
            state.myOffice.points = state.myOffice.points.filter(item => item.status !== undefined);   //  && item.status !== 'unavailable'
        }
        return state.myOffice;
    },
    myOfficeAmenitiesList(state) {
        return state.myOfficeAmenitiesList;
    },
    availableResources(state) {
        if (state.availableResources !== null) {
            state.allResources = [];
            if (state.availableResources.desk) {
                state.availableResources.desk.forEach(item => item.title = 'Рабочие места')
                state.allResources.push( ...state.availableResources.desk )
            }
            if (state.availableResources.employee) {
                state.availableResources.employee.forEach(item => item.title = 'Коллеги')
                state.allResources.push( ...state.availableResources.employee )
            }
            if (state.availableResources.meeting_room) {
                state.availableResources.meeting_room.forEach(item => item.title = 'Переговорные комнаты')
                state.allResources.push( ...state.availableResources.meeting_room )
            }
            return state.allResources;
        }
    },
    bookingDeskList(state) {
        return state.bookingDeskList;
    },
    bookingMeetingRoomList(state) {
        if (state.bookingMeetingRoomList) {
            state.bookingMeetingRoomList.items.forEach((item) => {
                if (item.dateRange && item.dateRange.start && item.dateRange.end) {
                    item.dateRange.start = moment(item.dateRange.start).format('HH:mm');
                    item.dateRange.end = moment(item.dateRange.end).format('HH:mm');
                    // item.dateRange.start = item.dateRange.start.substr(11, 5)
                    // item.dateRange.end = item.dateRange.end.substr(11, 5)
                }
            })
            return state.bookingMeetingRoomList;
        }
    },
    onlyDeskList(state) {
        if (state.myOfficePointFilter.length > 0) {
            return state.myOfficePointFilter.filter((point) => {
                return point.resource.type === 'desk'
            })
        }
        else {
            return state.myOffice.points.filter((point) => {
                return point.resource.type === 'desk'
            })
        }
    },
    onlyMeetingRoomList(state) {
        if (state.myOfficePointFilter.length > 0) {
            return state.myOfficePointFilter.filter((point) => {
                return point.resource.type !== 'desk'
            })
        } else {
            return state.myOffice.points.filter((point) => {
                return point.resource.type !== 'desk'
            })
        }
    },
    availableEmployees(state) {
        return state.availableEmployees.items;
    },
    citiesList(state) {
        return state.citiesList;
    },
    floorsList(state) {
        return state.floorsList;
    },
    selectedCountry(state) {
        return state.filter_country;
    },
    selectedCity(state) {
        return state.filter_cities;
    },
    selectedOffice(state) {
        return state.filter_offices;
    },
    selectedNewFloor(state) {
        return state.filter_floors;
    }
};

const actions = {
    [GET_MY_OFFICE](context, payload) {
        return new Promise(resolve => {
            ApiService.query(`my-office/offices/floor/${payload.id}`, {params: {...payload.params}})
                .then(({data}) => {
                    resolve(data);
                    context.commit(SET_MY_OFFICE, data);
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data);
                })
        });
    },
    [GET_MY_OFFICE_AMENITIES_LIST](context, payload) {
        ApiService.query(`my-office/amenities/list`, { params: {...payload} })
            .then(({ data }) => {
                context.commit(SET_MY_OFFICE_AMENITIES_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_MY_OFFICE_AMENITIES_LIST_FILTERED](context, payload) {
        context.commit(SET_MY_OFFICE_AMENITIES_LIST_FILTERED, payload);
    },
    [GET_AVAILABLE_RESOURCES](context, payload) {
        ApiService.query(`my-office/offices/floor/${payload.id}/filtering-available-resources`, { params: {...payload.params} })
            .then(({ data }) => {
                context.commit(SET_AVAILABLE_RESOURCES, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_BOOKING_DESK_LIST](context, payload) {
        ApiService.query(`my-office/bookings/floor/${payload.id}/desk/list`, { params: {...payload.params} })
            .then(({ data }) => {
                context.commit(SET_BOOKING_DESK_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [POST_RESERVE_DESK](context, payload) {
        return new Promise(resolve => {
            ApiService.post("my-office/booking/desk/create", payload.body)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.DESK_BOOKED") );
                    sendAmplitudeEvent('my-office-desk book', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('my-office-desk book', { 'result': 'error' });
                });
        });
    },
    [PUT_RESERVE_DESK_CANCEL](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`my-office/booking/${payload.id}/desk/cancel`)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.BOOKING_CANCELED") );
                    sendAmplitudeEvent('my-office-desk cancel', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('my-office-desk cancel', { 'result': 'error' });
                });
        })
    },
    [GET_AVAILABLE_EMPLOYEES](context, payload) {
        ApiService.query('my-office/bookings/meeting-room/find-employee', { params: {...payload.params} })
            .then(({ data }) => {
                context.commit(SET_AVAILABLE_EMPLOYEES, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [GET_BOOKING_MEETING_ROOM_LIST](context, payload) {
        ApiService.query(`my-office/bookings/floor/${payload.id}/meeting-room/list`, { params: {...payload.params} })
            .then(({ data }) => {
                context.commit(SET_BOOKING_MEETING_ROOM_LIST, data);
            })
            .catch(({ response }) => {
                context.commit(SET_ERROR, response.data);
            })
    },
    [POST_RESERVE_MEETING_ROOM](context, payload) {
        return new Promise(resolve => {
            ApiService.post("my-office/booking/meeting-room/create", payload.body)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.MEET_BOOKED") );
                    sendAmplitudeEvent('my-office-meeting-room book', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('my-office-meeting-room book', { 'result': 'error' });
                });
        });
    },
    [PUT_RESERVE_MEETING_ROOM_EDIT](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`my-office/booking/${payload.id}/meeting-room/edit`, payload.body)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.BOOKING_EDITED") );
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                });
        })
    },
    [PUT_RESERVE_MEETING_ROOM_CANCEL](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`my-office/booking/${payload.id}/meeting-room/cancel`)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.BOOKING_CANCELED") );
                    sendAmplitudeEvent('my-office-meeting-room cancel', { 'result': 'success' });
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                    sendAmplitudeEvent('my-office-meeting-room cancel', { 'result': 'error' });
                });
        })
    },
    [ASSIGN_MEMBER_TO_MEETING_ROOM](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`my-office/booking/${payload.id}/meeting-room/member/assign`, payload.body)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.MEMBER_ADDED") );
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                });
        })
    },
    [REVOKE_MEMBER_FROM_MEETING_ROOM](context, payload) {
        return new Promise(resolve => {
            ApiService.put(`my-office/booking/${payload.id}/meeting-room/member/revoke`, payload.body)
                .then(({ data }) => {
                    resolve(data);
                    context.commit(SET_SUCCESS, i18n.t("SUCCESS_MESSAGES.MEMBER_DELETED") );
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data);
                });
        })
    },
    [SELECT_NEW_COUNTRY]({ commit, rootState, state }, { id, page }) {
        state.citiesList = rootState.management.countryCity.find(country => country.id === id);
        if (state.citiesList) state.citiesList = state.citiesList.cities;
        if (id !== undefined) {
            commit(SET_NEW_COUNTRY, id);
            if (page !== 'planEdit') {
                commit(SET_NEW_CITY, undefined);
                commit(SET_NEW_OFFICE, undefined);
                commit(SET_NEW_FLOOR, undefined);
            }
        } else {
            commit(SET_NEW_COUNTRY, undefined);
            commit(SET_NEW_CITY, undefined);
            commit(SET_NEW_OFFICE, undefined);
            commit(SET_NEW_FLOOR, undefined);
        }
    },
    [SELECT_NEW_CITY]({ commit, dispatch, state }, { id, page }) {
        let cityObj = state.citiesList.find(city => city.id === id);
        if (id !== undefined) {
            dispatch(GET_OFFICES_LIST_BY_ID, { params: { city: cityObj.id } })
            commit(SET_NEW_CITY, id);
            if (page !== 'planEdit') {
                commit(SET_NEW_OFFICE, undefined);
                commit(SET_NEW_FLOOR, undefined);
            }
        } else {
            commit(SET_NEW_CITY, undefined);
            commit(SET_NEW_OFFICE, undefined);
            commit(SET_NEW_FLOOR, undefined);
        }
    },
    [SELECT_NEW_OFFICE]({ commit, dispatch, state }, { id, page }) {
        if (id !== undefined) {
            dispatch(GET_OFFICE, id).then(res => state.floorsList = res.floors);
            commit(SET_NEW_OFFICE, id);
            if (page !== 'planEdit') {
                commit(SET_NEW_FLOOR, undefined);
            }
        } else {
            commit(SET_NEW_OFFICE, undefined);
            commit(SET_NEW_FLOOR, undefined);
        }
    },
    [SELECT_NEW_FLOOR]({ commit, state }, { id }) {
        commit(SET_NEW_FLOOR, id)
    }
};

const mutations = {
    [SET_MY_OFFICE](state, data) {
        state.myOffice = data;
    },
    [SET_MY_OFFICE_AMENITIES_LIST](state, data) {
        state.myOfficeAmenitiesList = data;
    },
    [SET_MY_OFFICE_AMENITIES_LIST_FILTERED](state, data) {
        if (data) {
            state.myOfficePointFilter = [];
            state.myOffice.points.forEach(point => {
                data.items.forEach(item => {
                    if (point.resource.id === item.id) {
                        state.myOfficePointFilter.push(point);
                    }
                })
            })
        } else {state.myOfficePointFilter = []}
    },
    [SET_AVAILABLE_RESOURCES](state, data) {
        state.availableResources = data;
    },
    [SET_BOOKING_DESK_LIST](state, data) {
        state.bookingDeskList = data;
    },
    [SET_AVAILABLE_EMPLOYEES](state, data) {
        state.availableEmployees = data;
    },
    [SET_BOOKING_MEETING_ROOM_LIST](state, data) {
        state.bookingMeetingRoomList = data;
    },
    [SET_NEW_COUNTRY](state, data) {
        state.filter_country = data;
    },
    [SET_NEW_CITY](state, data) {
        state.filter_cities = data;
    },
    [SET_NEW_OFFICE](state, data) {
        state.filter_offices = data;
    },
    [SET_NEW_FLOOR](state, data) {
        state.filter_floors = data;
    },
    [SET_SELECTED_FLOOR](state) {
        let retrievedObject = localStorage.getItem("floorFilter");
        state.filter_country = JSON.parse(retrievedObject).filter_country;
        state.filter_cities = JSON.parse(retrievedObject).filter_cities;
        state.filter_offices = JSON.parse(retrievedObject).filter_offices;
        state.filter_floors = JSON.parse(retrievedObject).filter_floors;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
